<template>
    <router-view></router-view>    
</template>

<script>


export default {
    name: 'Main',

    components: {    
        //
    },

    data(){
        return {
            //
        }
    },

    computed: {
       //
    }
}
</script>
<template>
    <v-btn        
        text
        v-on:click="logOut"
      >
        <span class="mr-2 primary--text">Logout</span>
        <v-icon
            color="primary"
        >
            mdi-logout
        </v-icon>
      </v-btn>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
export default {
    methods: {
        ...mapActions({
            signOut: 'auth/signOut'
        }),

        ...mapMutations({
            setOverlay: 'termReports/setProgressOverlay',
            setStep: 'auth/setStep',
            setExpand: 'auth/setExpand',
            mainMenu: 'termReports/setMainMenu',
            enterMarks: 'termReports/setEnterMarks',
            editViewTermReports: 'termReports/setEditViewTermReports',
            welcomeMessage: 'termReports/setWelcomeMessage',            
        }),

        async logOut () {            
            this.setOverlay(true);
            this.mainMenu(true);
            this.enterMarks(false);
            this.editViewTermReports(false);
            this.welcomeMessage(true);
            this.setStep(1);            
            await this.signOut();
            this.setExpand(true);
            this.setOverlay(false);
            //console.log("logout complete");
            this.$router.replace('/')
        }

        
    }
}
</script>
<template>
    <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
    >
    <template v-slot:activator="{ on, attrs }">
        <v-text-field
            v-model="date"
            readonly
            v-bind="attrs"
            v-on="on"
            outlined
            hide-details
            :disabled="disabled"
            :filled="disabled"
            dense
        ></v-text-field>
        </template>
        <v-date-picker
            v-model="date"
            @input="menu = false"
            @change="setDate"
        ></v-date-picker>
    </v-menu>
</template>

<script>
export default {
    props: {
        initialDate: String,
        disabled: Boolean,
        field: String,
        term: Number,
    },

    data () {
        return {
            menu: false,
            date: this.initialDate,
        }
    },

    methods: {
        setDate () {
            if(this.field == 'term_start'){
                this.$emit('set-date', {
                    field: 'term_start',
                    date: this.date,
                    term: this.term
                })
                return;
            }
            
            if(this.field == 'term_end'){
                 this.$emit('set-date', {
                    field: 'term_end',
                    date: this.date,
                    term: this.term
                })
                return;
            }
        }
    }
}
</script>
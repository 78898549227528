import axios from 'axios';

class UploadCourseAssesmentService {
    
    upload(file, year, term, subjectId, classId, employeeId, onUploadProgress) {
        let formData = new FormData();

        formData.append("file", file);
        formData.append('year', year);
        formData.append('term', term);
        formData.append('subjectId', subjectId);
        formData.append('classId', classId);
        formData.append('employeeId', employeeId);

        return axios.post("/api/upload-course-assesment", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress
        });
    }   
}

export default new UploadCourseAssesmentService();
<template>
    <tr @click="recordSelected">
        <td style="padding: 0 6px">{{ markRecord.count }}</td>
        
        <td v-if="form6" style="padding: 0 6px">
            <span style="font-size: 0.8rem">{{ markRecord.class_id}}</span>
        </td>

        <td class="pt-1" style="padding: 0 6px">
            {{ markRecord.name }}
        </td> 

        <td v-if="!form6Term2" style="padding: 0 6px">
            <v-text-field
                v-model="markRecord.course_mark"
                outlined
                dense
                :filled="disableCourseMark"
                :disabled="disableCourseMark"
                hide-details
                type="number"                
                @blur="recordChanged"                
            >                
            </v-text-field>
        </td>

        <td v-if="!form6" style="padding: 0 6px">
            <v-text-field
                v-model="markRecord.exam_mark"
                outlined
                dense
                hide-details
                type="number"
                @blur="recordChanged"
                :filled="disableExamMark"
                :disabled="disableExamMark"
            ></v-text-field>
        </td>
        
        <td v-if="!form6" style="padding: 0 6px">
           <v-select
                v-model="codedComment"                
                :items="comments1"
                item-value="id"
                outlined
                dense
                hide-details
                return-object                
                @change="recordChanged"                
            >
                <template v-slot:selection="{item}">
                    {{ item.id }}
                </template>

                <template
                    v-slot:item=data
                > 
                    {{ data.item.id}} {{ data.item.comment }}
                </template> 

            </v-select>
        </td>

        <td v-if="!form6" style="padding: 0 6px"> 
            <v-select
                v-model="codedComment1"                
                :items="comments2"
                item-value="id"
                outlined
                dense
                hide-details
                return-object                
                @change="recordChanged"                
            >
                <template v-slot:selection="{item}">
                    {{ item.id }}
                </template>

                <template
                    v-slot:item=data
                > 
                    {{ data.item.id}} {{ data.item.comment }}
                </template>
            </v-select>
        </td>

        <td v-if="form6Term2" style="padding: 0 6px">
            <v-text-field
                v-model="markRecord.paper_1"
                outlined
                dense
                hide-details
                type="number"
                @blur="recordChanged"
                :filled="disableExamMark"
                :disabled="disableExamMark"
                               
            ></v-text-field>
        </td>

        <td v-if="form6Term2" style="padding: 0 6px">
            <v-text-field
                v-model="markRecord.paper_2"
                outlined
                dense
                hide-details
                type="number"
                @blur="recordChanged"
                :filled="disableExamMark"
                :disabled="disableExamMark"
            ></v-text-field>
        </td>

        <td v-if="form6Term2 && paper3" style="padding: 0 6px">
            <v-text-field
                v-model="markRecord.paper_3"
                outlined
                dense
                hide-details
                type="number"
                @blur="recordChanged"
                :filled="disableExamMark"
                :disabled="disableExamMark"
            ></v-text-field>
        </td>

        <td v-if="form6Term2" style="padding: 0 6px">
            <v-text-field
                v-model="markRecord.ia"
                outlined
                dense
                hide-details
                type="number"
                @blur="recordChanged"
                :filled="disableExamMark"
                :disabled="disableExamMark"
            ></v-text-field>
        </td>

        <td v-if="form6Term2" style="padding: 0 6px">
            <v-text-field
                v-model="p1WeightedScore"
                outlined
                dense
                hide-details
                type="number"                
                filled
                disabled                
            ></v-text-field>
        </td>

        <td v-if="form6Term2" style="padding: 0 6px">
            <v-text-field
                v-model="p2WeightedScore"
                outlined
                dense
                hide-details
                type="number"                
                filled
                disabled                
            ></v-text-field>
        </td>

        <td v-if="form6Term2 && paper3" style="padding: 0 6px">
            <v-text-field
                v-model="p3WeightedScore"
                outlined
                dense
                hide-details
                type="number"                
                filled
                disabled                
            ></v-text-field>
        </td>

        <td v-if="form6Term2" style="padding: 0 6px">
            <v-text-field
                v-model="iaWeightedScore"
                outlined
                dense
                hide-details
                type="number"                
                filled
                disabled                
            ></v-text-field>
        </td>

        <td v-if="form6Term2" style="padding: 0 6px">
            <v-text-field
                v-model="totalWeightedScore"
                outlined
                dense
                hide-details
                type="number"                
                filled
                disabled                
            ></v-text-field>
        </td>

        <td v-if="form6" style="padding: 0 6px">
            <v-textarea
                counter
                maxlength="300"
                rows="1"
                v-model="markRecord.custom_comment"
                @blur="recordChanged"
                @click="openBottomSheet"                
                :rules="rules"
                style="font-size: 0.8rem"
            ></v-textarea>
        </td>
        <td style="padding: 0 6px">
            <v-tooltip 
                bottom
                
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        small
                        color="green"                        
                        v-bind="attrs"
                        v-on="on"
                        v-show="saved"
                        class="mr-2"
                    >
                        mdi-check-all
                    </v-icon>
                </template>    
                <span>Saved</span>                
            </v-tooltip>
            <v-tooltip 
                top
                
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        small
                        color="red"                       
                        v-bind="attrs"
                        v-on="on"
                        v-show="error"
                    >
                        mdi-alert-circle
                    </v-icon>
                </template>    
                <span>Error</span>               
            </v-tooltip>
            <v-progress-circular
                indeterminate
                color="primary"
                v-show="saving"
                size="16"
                width="3"
            ></v-progress-circular>
        </td>
    </tr>

    
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    props:{
        studentRecord: Object,
        paper3: Boolean,
        weightings: Array,
        formLevel: Number,        
    },
    // created () {
    //     this.initialize();
    // },  
    watch: {        
        codedComments: {
            deep: true,
            handler(value){
                if(value.length != 0){
                    this.setComments();
                }
            }
        },
        codedComment: {
            handler(value){
                //console.log(value);
                this.markRecord.coded_comment = value.id;
            }
        },
        codedComment1: {
            handler(value){
                //console.log(value);
                this.markRecord.coded_comment_1 = value.id;
            }
        },

        weightings: {
            handler () {
                this.initialize();
            }
        },
        
        'markRecord.custom_comment': {
            handler (newValue, oldValue) {
                if(oldValue !== undefined) this.recordChanged();
            }
        }
       
    },       
    data() {
        return {
            commentsConduct: [],
            commentsApplication: [],
            commentsPreparedness: [],            
            comments1: [],
            comments2: [],            
            markRecord: {},
            codedComment: { id: null, comment: null },
            codedComment1: { id: null, comment: null },
            conduct: { id: null, comment: null },
            application: { id: null, comment: null },
            preparedness: { id: null, comment: null }, 
            selectColumns:[
                { text: 'Conduct', model: 'conduct' }
            ],
            saved: false,
            saving: false,
            error: false,
            disableConduct: false,
            disableApplication: false,
            disablePreparedness: false,
            disableCodedComment: false,
            disableCodedComment1: false,
            disableExamMark: false,
            disableCourseMark: false,
            termTwo: false,
            form6: false,
            rules: [v => {
                if(v && v.length <= 300) return true;
                else return 'Max 300 characters' 
            }],
            p1Weight: 10,
            p2Weight: null,
            p3Weight: null,
            iaWeight: null,           
        }
    },
    computed: {
        ...mapGetters({
            codedComments: 'termReports/getCodedComments',
            employeeId: 'auth/getEmployeeId',
            primaryColor: 'termReports/getPrimaryColor',
            colorDarken: 'termReports/getColorDarken',
            marksEntered: 'termReports/getSubjectStudentsMarksEntered',
            lessonSelected: 'termReports/getLessonSelected',
            table2Records: 'termReports/getTable2Records',
            currentTerm: 'termReports/getCurrentTerm',
            commentSheet: 'termReports/getCommentSheetEnterMarks', 
        }),
        themeColor(){          
            return this.primaryColor + ' ' + this.colorDarken;
        },
        total(){
            let formLevel = this.lessonSelected.formLevel;
            
            if(formLevel == 5) return 'NA';
            else if(this.currentTerm == 2){
                if(formLevel == 6) return 'NA'
            }
            else if(this.currentTerm == 3){
                if(formLevel == 3 || formLevel == 4) return 'NA'
            }
            return (this.markRecord.course_mark * 0.6 + this.markRecord.exam_mark * 0.4).toFixed(1);
        },
        
        p1WeightedScore () {
            return this.markRecord.paper_1 ? this.round((this.markRecord.paper_1 /  100) * this.p1Weight, 1) : null;
        },

        p2WeightedScore () {
            return this.markRecord.paper_2 ? this.round((this.markRecord.paper_2 /  100) * this.p2Weight, 1) : null;
        },

        p3WeightedScore () {
            return this.markRecord.paper_3 ? this.round((this.markRecord.paper_3 /  100) * this.p3Weight, 1) : null;
        },

        iaWeightedScore () {
           return this.markRecord.ia ? this.round((this.markRecord.ia /  100) * this.iaWeight, 1) : null;
        },

        totalWeightedScore () {
            let total = 0;
            if( 
                this.markRecord.paper_1 ||
                this.markRecord.paper_2 ||
                this.markRecord.paper_3 ||
                this.markRecord.ia
            ) {
                total = 
                (this.markRecord.ia / 100) * this.iaWeight +
                (this.markRecord.paper_3 / 100) * this.p3Weight +
                (this.markRecord.paper_2 /  100) * this.p2Weight +
                (this.markRecord.paper_1 /  100) * this.p1Weight;
                total = this.round(total, 1);                
                return total;
            }
            return null;
        },

        form6Term2 () {
            if(
                this.lessonSelected.formLevel == 6 &&
                this.currentTerm == 2
            ) return true;
            return false;
        }

       
    },
    methods : {
        ...mapMutations({
            setUpdatedRecord: 'termReports/setUpdatedTable2Record',
            setStudentSelected: 'termReports/setMarkSheetStudentSelected',
            setMarkSheetEntered: 'termReports/setSubjectStudentsMarksEntered',
            setCommentSheet: 'termReports/setCommentSheetEnterMarks',
            setSelectedTable2RecordIndex: 'termReports/setSelectedTable2RecordIndex',
        }),

        ...mapActions({
            save: 'termReports/saveTable2Record',
        }),
        
        initialize(){
            //this.markRecord = {...this.studentRecord};
            this.markRecord = this.studentRecord;
            this.setStudentSelected(null);
            if(this.lessonSelected.formLevel == 6) this.form6 = true;
            //console.log(this.markRecord);
            this.savedDataCheck();
            this.setComments();
            //console.log('initializing..')
            this.setDisabledFields();
            //if(this.currentTerm == 2) this.termMarkOnly = true;
            //console.log(this.currentTerm);
            this.weightings.forEach(value => {
                switch (value.assesment_id) {
                    case 1 :
                        this.p1Weight = value.weight;
                        break;
                    case 2 :
                        this.p2Weight = value.weight;
                        break;
                    case 3 :
                        this.p3Weight = value.weight;
                        break;
                    case 4 :
                        this.iaWeight = value.weight;
                        break;
                }
            })            
        },

        async recordChanged(){
            let total = null;
            console.log("updating record");
            //console.log('this record has changed ...');            
            //console.log(this.recordBlankCheck());
            //console.log(this.markRecord);
            if(!this.recordBlankCheck()){
                console.log('record not blank');
                //console.log('record changed...');            
                this.saving = true;
                this.saved = false;
                this.error = false;

                total = 
                (this.markRecord.ia / 100) * this.iaWeight +
                (this.markRecord.paper_3 / 100) * this.p3Weight +
                (this.markRecord.paper_2 /  100) * this.p2Weight +
                (this.markRecord.paper_1 /  100) * this.p1Weight;
                total = this.round(total, 1);
                
                
                if(this.formLevel == 6) this.markRecord.exam_mark = total;
                this.setUpdatedRecord(
                    {
                        "student_id" : this.markRecord.student_id,
                        "year" : this.markRecord.year,
                        "term" : this.markRecord.term,
                        "subject_id" : this.markRecord.subject_id,
                        "exam_mark" : this.markRecord.exam_mark,
                        "course_mark" : this.markRecord.course_mark,                   
                        "coded_comment" : this.codedComment.id,
                        "coded_comment_1" : this.codedComment1.id,
                        "paper_1" : this.markRecord.paper_1,
                        "paper_2" : this.markRecord.paper_2,
                        "paper_3" : this.markRecord.paper_3,
                        "ia" : this.markRecord.ia,
                        "custom_comment" : this.markRecord.custom_comment,
                        "employee_id" : this.employeeId,                   
                    }
                );
                //console.log(`Employee: ${this.employeeId}`);
                // this.markRecord.employee_id = this.employeeId;
                // this.setUpdatedRecord(this.markRecord);
            
                let response = await this.save();
                //console.log(response);

                if(response && (response.status === 200 || response.status === 201)){
                    this.saving = false;
                    this.saved = true;
                    this.error = false;
                    //console.log(this.marksEntered);
                    //let entered = this.marksEntered;
                    //this.setMarkSheetEntered(++entered);
                }
                else{
                    this.saving = false;
                    this.error = true;
                    this.saved = false;
                }    
            }
            else{
                //console.log('record blank');
            }                  
        },
        recordBlankCheck(){
            //console.log('record blank check..');
            let record = [];
            let blank = true;                     
            record.push(this.markRecord.course_mark);
            record.push(this.markRecord.exam_mark);            
            record.push(this.codedComment.id);
            record.push(this.codedComment1.id);
            record.push(this.markRecord.paper_1);
            record.push(this.markRecord.paper_2);
            record.push(this.markRecord.paper_3);
            record.push(this.markRecord.ia);
            record.push(this.markRecord.custom_comment);
            //console.log(record);            
            record.forEach(element => {
                if(element) blank = false;
            })
            //console.log(blank);            
            return blank;
        },
        savedDataCheck(){
            if(!this.recordBlankCheck()) this.saved = true;
        },
        recordSelected(){
            //console.log('marksheetrecord: ', this.markRecord);
            //console.log("record selected");
            let records = this.table2Records;
            //console.log(records);
            let entered = 0;
            records.forEach(record => {
                if(record.course_mark || record.exam_mark || record.conduct || record.application || record.preparedness || record.coded_comment || record.coded_comment_1){
                    entered++;
                }
            })
            console.log(entered);
            //console.log(records);          
            this.setStudentSelected(this.markRecord.name);
        },        
        setComments(){ 
            this.comments1.push({id: null, comment: null});  
            this.comments2.push({id: null, comment: null});
            //console.log(this.codedComments);  
            this.codedComments.forEach(record =>{
                this.comments1.push({ id: record.id, comment: record.detail });
                this.comments2.push({ id: record.id, comment: record.detail });
            });           
            
            if(this.studentRecord.coded_comment){
                this.codedComment = { id: this.studentRecord.coded_comment };
            } 
            if(this.studentRecord.coded_comment_1){
                this.codedComment1 = { id: this.studentRecord.coded_comment_1 };
            }
            
        },
        setDisabledFields(){           
            
            if(this.currentTerm == 2 && this.lessonSelected.formLevel < 5){
                //this.termTwo = true;
                this.disableExamMark = true;            
            }
            else if(this.currentTerm == 2 && this.lessonSelected.formLevel == 5){
                this.disableCourseMark = true;
            }

            // if(this.currentTerm == 2 && this.lessonSelected.formLevel != 5){
            //     this.disableExamMark = true
            // }
            
        },
        

        round (number, decimalPlaces) {            
            return Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces)
        },

        openBottomSheet () {
            //console.log(this.markRecord);
            this.setSelectedTable2RecordIndex(this.table2Records.indexOf(this.studentRecord));
            this.setCommentSheet(true);
        },

        inputChanged () {
            console.log("input changed");
        }
    }
}
</script>

<style scoped> 
    ::v-deep .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details{
        padding: 0 6px;
    }

    /* Chrome, Safari, Edge, Opera */
    ::v-deep input::-webkit-outer-spin-button,
    ::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    text-align:center
    }

    /* Firefox */
    ::v-deep input[type=number] {
    -moz-appearance: textfield;
    text-align: center;
    }
</style>
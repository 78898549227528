<template>
     <v-row>
        <v-col
            cols="12"
        >
            <v-date-picker
                label="Select Date Range"
                v-model="dateRange"
                range
                @change="setDateRange"
            ></v-date-picker>
        </v-col>
    </v-row>
</template>

<script>
// import DatePickerRange from './DatePickerRange'
export default {
    props: {
        formClasses: {
            type: Array,
            default: () => []
        }
    },

    created () {
        this.dateRange = [];
    },

    components: {
        // DatePickerRange,
    },

    watch: {
        dateRange (val) {
            if(val.length == 1) this.$emit('set-date-range', this.dateRange); 
        }
    },

    data: () => ({
        dateRange: [],
        selectedClassId: null,
    }),

    methods: {
        setDateRange () {
            this.$emit('set-date-range', this.dateRange);            
        },

    }
}
</script>
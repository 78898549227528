<template>
    <v-container fluid>
        <v-card
            class="mx-auto"
            max-width="300"
        >
            <v-card-title
                class="primary--text secondary"
            >
                Student Attendance
            </v-card-title>
            <v-list>                
                <v-list-item-group color="primary">
                    <template v-for="(report, i) in reports">
                        <v-list-item                            
                            :key="report.value"
                            @click="displayReportMenu(report)"
                        >
                            <v-list-item-content>
                                <v-list-item-title >
                                    {{ report.title}}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider
                            v-if="i < reports.length - 1"
                            :key="i"
                        >                        
                        </v-divider>
                    </template>
                    
                </v-list-item-group>
            </v-list>
        </v-card>

        <v-dialog
            v-model="dialog"
            max-width="90vw"
            persistent            
        >
            <v-card height="85vh" class="pb-0">
                <iframe 
                    ref="pdf" 
                    style="width:100%; height:100%" 
                    frameBorder="0" 
                    :src="src"
                ></iframe>

                <v-btn
                    dark
                    color="error"
                    small
                    fab
                    style="position:absolute; top:10px; right:10px; z-index:100"
                    @click="closeReportMenu"
                >
                    <v-icon dark>mdi-close</v-icon>
                </v-btn>
                
                <v-overlay
                    :absolute="absolute"
                    :value="overlay"
                    opacity="0.5"
                    color="#A0A0A0"
                >                
                    <v-card                        
                        light
                        :max-width="maxWidth"
                        class="pa-4"
                    >
                        <v-card-title>
                            <v-btn 
                                color="primary" 
                                text
                                v-if="showStudents"
                                @click="displayStudents"
                                class="mr-4"
                            >
                                <v-icon left >mdi-chevron-left</v-icon>
                                Back To Students
                            </v-btn>
                            <v-spacer v-if="showStudents"></v-spacer>
                            {{ cardTitle }}
                        </v-card-title>

                        <v-card-text
                            v-if="menu.attendanceClass"
                        >
                            <AttendanceMenu 
                                :form-classes="formClasses"
                                v-on:set-date-range="setDateRangeClassAttendance"
                                v-on:set-class-id="setClassIdClassAttendance"
                            />
                        </v-card-text>

                        <v-card-text
                            v-if="menu.attendanceStudent"
                        >
                            <AttendanceMenuStudent
                                v-on:set-date-range="setStudentAttendanceDateRange"
                                v-on:set-student-id="setStudentAttendanceStudentId"
                                v-on:show-students="setShowStudents"
                                v-on:show-attendance-report-menu="showStudentReportMenu"
                                v-on:show-student-attendance-report="showStudentAttendanceReport"
                                :show-students="displayAllStudents"
                                :show-attendance-report-menu="showAttendanceReportMenu"
                                :delete-attendance="deleteAttendance"
                                ref="attendanceMenuStudent"
                            />
                        </v-card-text>

                        <v-card-text
                            v-if="menu.attendanceLate"
                        >
                            <AttendanceMenuLate
                                v-on:set-date-range="setLateAttendanceDateRange"
                            />
                        </v-card-text>

                        <v-card-text class="pb-0" v-if="menu.attendanceSummary">
                            <AttendanceMenuSummary 
                                v-on:set-date-range="setDateRangeSummaryAttendance"
                            />
                        </v-card-text>
                        

                        <v-card-actions 
                            class="py-0"                            
                        >
                            <v-btn
                                v-if="actions"
                                color="primary"
                                dark
                                @click="displayReport"
                                :block="blockButton"
                                class="ml-auto"
                            >
                                Display Report
                            </v-btn>
                            <v-spacer  v-if="showStudents"></v-spacer>
                            <!-- <v-btn
                                v-if="showStudents && !deleteSelected"
                                color="primary"
                                text
                                class="mr-4"
                                @click="showDeleteAttendance"
                            >
                                Delete Attendance
                            </v-btn> -->
                            <v-btn
                                v-if="deleteSelected"
                                color="primary"
                                text
                                class="mr-4"
                                @click="closeDeleteSelectedAttendance"
                            >
                                Close
                            </v-btn>
                            <v-btn
                                v-if="deleteSelected"
                                color="primary"
                                outlined
                                class="mr-4"
                                @click="dialogConfirmDelete = true"
                            >
                                Delete Selected
                            </v-btn>
                            <!-- <v-btn
                                v-if="showStudents && !deleteSelected"
                                color="primary"
                                dark
                                @click="showStudentReportMenu(true)"
                            >
                                Attendance Report
                            </v-btn> -->
                        </v-card-actions>

                         <v-overlay
                            :absolute="absolute"
                            :value="overlayLoading"                    
                            color="grey lighten-5"
                            opacity="0.9"
                            class="pa-5"
                        > 
                            <v-progress-circular
                                indeterminate 
                                size="64"                            
                                color="primary"                                
                            ></v-progress-circular>                   
                        </v-overlay>

                        <v-dialog v-model="dialogConfirmDelete" width="500" persistent>
                            <v-card>
                                <v-card-title class="text-h5" >Delete Records</v-card-title>
                                <v-card-text>Are you sure you want to delete these records?</v-card-text>
                                <v-divider></v-divider>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="red"
                                        text
                                        @click="dialogConfirmDelete = false"
                                        class="mr-4"
                                    >
                                        Cancel Delete
                                    </v-btn>
                                    <v-btn
                                        color="red"
                                        outlined
                                        @click="confirmDeleteAttendance"
                                    >
                                        Delete Attendance
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>

                        <v-snackbar
                            v-model="snackbar.show"
                            :color="snackbar.color"
                            timeout="2000"
                        >
                            {{ snackbar.text}}
                            <template
                                v-slot:action="{ attrs }"
                            >
                                <v-btn color="white" text v-bind="attrs" @click="snackbar.show = false">Close</v-btn>
                            </template>
                        </v-snackbar>
                    </v-card>

                </v-overlay>                             
            </v-card>

            <v-container
                fluid
                class="pa-0"
                v-if="!overlay"
            >
                <v-sheet>
                    <v-row
                        justify="end"
                        class="ma-0"
                    >
                        <v-btn
                            color="primary"
                            small
                            dark
                            @click="closeReport"
                            class="my-2 mr-4"
                        >
                            Close
                        </v-btn>
                    </v-row>
                </v-sheet>
            </v-container>
           
        </v-dialog>

        <AttendanceSetTermDates 
            :initial-dialog="dialogSetTermDates"
            v-on:set-dialog="setDialogSetTermDates"
        />

        <AttendanceUpdateReports
            :initial-dialog="dialogUpdateTermReports"
            v-on:set-dialog="setDialogUpdateTermReports"
        />
        
    </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import AttendanceMenu from './AdminReportsAttendanceMenu'
import AttendanceMenuStudent from './AdminReportsAttendanceMenuStudent.vue'
import AttendanceMenuLate from './AdminReportsAttendanceMenuLate.vue'
import AttendanceMenuSummary from './AdminReportsAttendanceMenuSummary.vue'
import AttendanceSetTermDates from './AdminAttendanceStudentSetTermDates.vue'
import AttendanceUpdateReports from './AdminAttendanceStudentUpdateReports.vue'
export default {
    components: {
        AttendanceMenu,
        AttendanceMenuStudent,
        AttendanceMenuLate,
        AttendanceMenuSummary,
        AttendanceSetTermDates,
        AttendanceUpdateReports,
    },

    mounted: function () {
        this.initialize();
    },
    
    data: () => ({
        reports: [
            {id: 1, title: "Attendance - Class", value: 'attendanceClass', menu: true},
            {id: 2, title: "Attendance - Student", value: 'attendanceStudent', menu: true},
            {id: 3, title: "Attendance - Late List", value: 'attendanceLate', menu: true},
            {id: 4, title: "Attendance - Summary Statistics", value: 'attendanceSummary', menu: true},
            {id: 4, title: "Attendance - Set Term", value: 'attendanceSetTermDates', menu: true},
            {id: 5, title: "Attendance - Update Term Reports", value: 'attendanceUpdateTermReports', menu: true},
            // {id: 5, title: "Attendance Archive", value: 'attendanceArchive', menu: true},
        ],
        dialog: false,
        src: '',
        absolute: true,
        overlay: false,
        overlayLoading: false,
        cardTitle: '',        
        selectedReport: null,
        menu: {},        
        formClasses: [],
        dateRange: [],
        dateRangeClassAttendance: [],
        selectedClassIdClassAttendance: null,
        snackbar: {
            show: false,
            color: null,
            text: null,
        },
        selectedStudentId: null,
        dateRangeAttendanceStudent: [],
        dateRangeLate: [],
        dateRangeSummary: [],
        maxWidth: 380,
        actions: true,
        showStudents: false,
        displayAllStudents: false,
        blockButton: false,
        showAttendanceReportMenu: false,
        deleteAttendance: false,
        deleteSelected: false,
        dialogConfirmDelete: false,
        dialogSetTermDates: false,
        dialogUpdateTermReports: false,
    }),

    computed: {
        ...mapGetters({
            attendanceRecordsSelected: 'admin/getAttendanceRecordsSelected',
        })
    },

    watch: {
        dateRange: {
            handler (val) {
                console.log(val);
            }
        }
    },

    methods: {
        ...mapActions({
            getFormLevels: 'admin/getFormLevels',
            getSubjects: 'admin/getSubjects',
            getFormClasses: 'admin/getFormClasses',
            getReportsPosted: 'admin/getReportsPosted',
            deleteAttendanceStudent: 'admin/deleteAttendanceStudent',
            archiveStudentAttendance: 'admin/archiveStudentAttendance',
            postStudentAttendanceArchive: 'admin/postStudentAttendanceArchive',
        }),

        ...mapMutations({
            setAttendanceSelectedStudent : 'admin/setAttendanceSelectedStudent',
            setStudentArchiveRowStart: 'admin/setStudentArchiveRowStart',
        }),

        async initialize () {
            const formClassesPromise = this.getFormClasses();

            const { data: dataFormClasses } = await formClassesPromise;
            this.formClassesRecords = dataFormClasses;
            dataFormClasses.forEach(record => {
                if(record.form_level)
                this.formClasses.push(record.id);
            })
        },

        displayReportMenu (report) {
            this.overlay = true;
            this.maxWidth = 380;
            this.blockButton = true;

            if(report.value == 'attendanceSummary'){
                this.maxWidth = '80vw';
                this.blockButton = false;
            }

            if(report.value == 'attendanceStudent'){
                this.maxWidth = '60vw';
                this.actions = false;
            }

            if(report.value == 'attendanceSetTermDates'){
                this.dialogSetTermDates = true;
                return;
            }

            if(report.value == 'attendanceUpdateTermReports'){
                this.dialogUpdateTermReports = true;
                return;
            }

            if(report.value === 'attendanceArchive'){
                // this.archive();
                this.postArchive();
                return;
            }

            this.selectedReport = report;

            this.reports.forEach(item => {                
                if(item.value == report.value){
                    if(item.menu){
                        this.cardTitle = item.title;
                        this.menu[item.value] = true;
                    }
                    else{
                        this.displayReport();
                    }                    
                    
                }
                
                else{
                    this.menu[item.value] = false;
                }                 
                
            })

            this.dialog = true;           
        },

        clearMenu () {
            Object.keys(this.menu).forEach(key => {
                this.menu[key] = false;
            })
        },

        displayReport () {
            const baseUrl = process.env.VUE_APP_API_URI  + '/api/';
            switch (this.selectedReport.value) {
                case "attendanceClass":
                    if(this.dateRangeClassAttendance.length == 0 || !this.selectedClassIdClassAttendance) {
                        this.snackbar.show = true;
                        this.snackbar.color = "red";
                        if(this.dateRangeClassAttendance.length == 0 && !this.selectedClassIdClassAttendance){
                            this.snackbar.text = "Please Select Class and Date.";
                            return;
                        }
                        
                        if(this.dateRangeClassAttendance.length == 0){
                            this.snackbar.text = "Please Select Date.";
                            return;
                        }

                        if(!this.selectedClassIdClassAttendance){
                            this.snackbar.text = "Please Select Class";
                            return;
                        }
                    }
                    this.src = baseUrl + "attendance-report-class" + "?" +
                    "dates[]="+this.dateRangeClassAttendance[0]+"&dates[]="+this.dateRangeClassAttendance[1] +
                    "&classId="+this.selectedClassIdClassAttendance 
                    console.log(this.src)
                    break;
                case "attendanceStudent":
                    if(this.dateRangeAttendanceStudent.length == 0){
                        this.src = baseUrl + "student-attendance-report/" + this.selectedStudentId;
                    }
                    else if(this.dateRangeAttendanceStudent.length == 1){
                        this.src = baseUrl + "student-attendance-report/" + this.selectedStudentId + "/" + this.dateRangeAttendanceStudent[0];
                    }
                    else this.src = baseUrl + "student-attendance-report/" + this.selectedStudentId + "/" + this.dateRangeAttendanceStudent[0]
                    + "/" + this.dateRangeAttendanceStudent[1];
                    break; 
                case "attendanceLate":
                    this.src = baseUrl + "attendance-report-late" + "?" +
                    "dates[]="+this.dateRangeLate[0]+"&dates[]="+this.dateRangeLate[1];
                    console.log(this.src)
                    break;
                case "attendanceSummary":
                    this.src = baseUrl + "attendance-report-summary?" +
                    "dates[]=" + this.dateRangeSummary.join("&dates[]=")
                    break;

            }
            this.overlay = false;
        },

        closeReportMenu () {
            this.showStudents = false;
            this.actions = true;
            this.dialog = false;
            this.src = '';
            this.selectedReport = null;
            this.dateRangeClassAttendance = [];
            this.dateRangeLate = [];
            this.dateRangeSummary = [];
            this.selectedClassIdClassAttendance = null;
            this.showAttendanceReportMenu = false;
            this.showStudents = false;
            this.setAttendanceSelectedStudent(null); 
            this.clearMenu();
        },

        closeReport () {
            if(this.selectedReport.menu){
                this.overlay = true; 
                this.src = '';
                this.showStudents = false;   
                this.showAttendanceReportMenu = false;            
            }
            else this.closeReportMenu();
            // console.log(this.dateRange);
        },

        setDateRange (dates) {            
            this.dateRange = dates;
        },

        setDateRangeClassAttendance (dateRange) {
            this.dateRangeClassAttendance = dateRange;
        },

        setDateRangeSummaryAttendance (dateRange) {
            this.dateRangeSummary = dateRange;
        },

        setClassIdClassAttendance (classId) {
            this.selectedClassIdClassAttendance = classId
        },

        setStudentAttendanceDateRange (dateRange) {
            this.dateRangeAttendanceStudent = dateRange;
        },

        setLateAttendanceDateRange (dateRange) {
            console.log(dateRange);
            if(dateRange.length == 1){
                this.dateRangeLate = [
                    dateRange[0],
                    dateRange[0]
                ]
                return;
            }
            this.dateRangeLate = dateRange
        },

        setStudentAttendanceStudentId (studentId) {
            this.selectedStudentId = studentId;
        },

        setShowStudents () {
            this.showStudents = true;
            this.displayAllStudents = false;
        },

        displayStudents () {
            this.displayAllStudents = !this.displayAllStudents;
            this.showStudents = false;
        },

        showStudentReportMenu (display = true) {
            this.showAttendanceReportMenu = display;
        },

        showStudentAttendanceReport (dateRange) {
            // console.log(dateRange);
            this.dateRangeAttendanceStudent = dateRange;
            this.displayReport();
        },

        showDeleteAttendance () {
            this.deleteAttendance = true;
            this.deleteSelected = true;
        },

        async confirmDeleteAttendance () {
            this.dialogConfirmDelete = false;
            this.overlayLoading = true;
            if(this.emptyRecordsCheck()){
                this.snackbar.show = true;
                this.snackbar.color = 'red';
                this.snackbar.text = "Please note only attendance records with at least one present session can be deleted."
                return;
            }
            try {
                const { data } = await this.deleteAttendanceStudent();
                this.snackbar.show = true;
                this.snackbar.color = 'primary';
                this.snackbar.text = `${data} Attendance records deleted.`;
                this.$refs.attendanceMenuStudent.getStudentAttendanceRecords();
            } catch (error) {
                this.snackbar.show = true;
                this.snackbar.color = 'red';
                this.snackbar.text = "An Error Occured."
                if(error.response) console.log(error.response)
                else console.log(error)
            }
            this.overlayLoading = false;
        },

        emptyRecordsCheck () {
            let emptyRecords = true;
            this.attendanceRecordsSelected.forEach(record => {
                if(record.attendance_am != 'P'|| record.attendance_pm != 'P'){
                    emptyRecords = false;
                }
            })
            return emptyRecords;
        },

        closeDeleteSelectedAttendance () {
            this.deleteAttendance = false;
            this.deleteSelected = false;
        },

        setDialogSetTermDates (val) {
            this.dialogSetTermDates = val;
        },

        setDialogUpdateTermReports (val) {
            this.dialogUpdateTermReports = val
        },

        async archive () {
            console.log('archiving...')
            try {
                const { data: { date, records } } = await this.archiveStudentAttendance();
                // console.log(data)
                console.log(`date: ${date} records: ${records}`)
                if (!records) {
                    console.log('archiving complete')
                } else {
                    this.archive();
                }
            } catch (error) {
                if(error.response) console.log(error.response)
                console.log(error)
            }
        },

        async postArchive () {
            try {
                let { data } = await this.postStudentAttendanceArchive();
                let totalRows = data.rows;
                let rowsProcessed = data.rowsProcessed;
                let position = data.position
                console.log(`${rowsProcessed} / ${totalRows}`)
                while(rowsProcessed < totalRows){
                    this.setStudentArchiveRowStart(position)
                    let { data } = await this.postStudentAttendanceArchive();
                    console.log(`Rows Processed: ${data.rowsProcessed}`)
                    console.log(`Position: ${data.position}`)
                    rowsProcessed += data.rowsProcessed
                    position = data.position
                    console.log(`${rowsProcessed} / ${totalRows}`)
                }
                console.log('stop');
            } catch (error) {
                if(error.response) console.log(error.response)
                else console.log(error)
            }
        }
    }
}
</script>


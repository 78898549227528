<template>
    <v-container class="pb-0 d-flex justify-center">
        <v-card
            flat
            :width="width"
            :class="cardClass"
        >
            <v-data-table
                :items="table2Records"
                :loading="loading"
                :headers="headers"
                :height="height"            
                fixed-header           
                class="enter-marks-table"
                disable-pagination
                hide-default-footer                                       
            >
                <template v-slot:item="props">
                    <Record
                        v-bind:studentRecord="props.item"
                        v-bind:paper3="paper3"
                        v-bind:weightings="subjectWeightings"
                        v-bind:formLevel="lessonSelected.formLevel"
                    />
                </template>
            </v-data-table>
        </v-card>
    </v-container>
</template>

<script>
import Record from './EnterMarksSheetRecord';
import { mapGetters } from 'vuex';
export default {
    components: {
        Record,
    },
    
    data: () =>({
        loading: false,
        height: "55vh",
        pagination: false,
        footer: false,                
        headers: [],
        p1Weight: null,
        p2Weight: null,
        p3Weight: null,       
        iaWeight: null,        
    }),    
    computed:{
        ...mapGetters({            
            table2Records: 'termReports/getTable2Records',
            currentTerm: 'termReports/getCurrentTerm',
            lessonSelected: 'termReports/getLessonSelected',
            subjectWeightings: 'termReports/getSubjectWeightings',
        }),

        width () {
            return (this.lessonSelected.formLevel < 6) ? 900 : 1080;
        },

        cardClass () {
            return (this.lessonSelected.formLevel < 6) ? 'px-6' : 'px-0';
        },

        paper3 () {
            return this.p3Weight ? true : false;
        }
    },
    watch: {
        lessonSelected: {
            handler(){
                //this.initialize();
            }
        },
        
        subjectWeightings: {
            handler () {
                //console.log(val);
                this.initialize();
            }
        }
    },
    methods: { 
       initialize(){
           console.log(this.lessonSelected);
                    
           if(this.lessonSelected.formLevel < 6){
               this.headers = [
                {text: '', value: 'count', align: 'left', width: '45', sortable: false},
                {text: 'Name', value: 'name', width: '265', sortable: false},
                {text: 'Course', value: 'course', align: 'center', width: '65', sortable: false,},            
                {text: 'Exam', value: 'exam', align: 'center', width: '65', sortable: false,},                    
                {text: 'Coded Comment 1', value: 'codedComment1', align: 'center', width: '100', sortable: false,},            
                {text: 'Coded Comment 2',  value: 'codedComment2', align: 'center', width: '100', sortable: false,},
                {text: ' ', value: 'actions', align: 'center', sortable: false, width: '40'},                        
            ]    
           }
           else if(this.lessonSelected.formLevel == 6 && this.currentTerm == 2){

               this.subjectWeightings.forEach(value => {
                   switch(value.assesment_id){
                       case 1:
                           this.p1Weight = value.weight;
                           break;
                       case 2:
                           this.p2Weight = value.weight;
                           break;
                       case 3:
                           this.p3Weight = value.weight;
                           break;
                       case 4:
                           this.iaWeight = value.weight;
                           break;                           
                   }
               });

               const paperHeaders = [
                   {text: '', value: 'count', width: '30', sortable: false},
                   {text: 'Class', value: 'class_id', width: '65', sortable: false},
                   {text: 'Name', value: 'name', width: '150', sortable: false}, 
                   {text: 'P1 [100]', value: 'paper_1', width: '55', sortable: false, paper: 1},
                   {text: 'P2 [100]', value: 'paper_2', width: '55', sortable: false, paper: 2},
                   {text: 'P3 [100]', value: 'paper_3', width: '55', sortable: false, paper: 3},
                   {text: 'IA [100]', value: 'ia', width: '55', sortable: false, paper: 4},
                   {text: 'P1 ['+ this.p1Weight + ']', value: 'paper_1_weight', width: '60', sortable: false, paper: 1},
                   {text: 'P2 [' + this.p2Weight + ']', value: 'paper_2weight', width: '60', sortable: false, paper: 2},
                   {text: 'P3 [' + this.p3Weight + ']', value: 'paper_3weight', width: '60', sortable: false, paper: 3},
                   {text: 'IA [' + this.iaWeight + ']', value: 'ia_weight', width: '60', sortable: false, paper: 4},
                   {text: 'Total', value: 'total', width: '60', sortable: false},
                   {text: 'Comments', value: 'custom_comment', width: '297', sortable: false},
                   {text: ' ', value: 'actions', align: 'center', sortable: false, width: '40'},
               ] 

               

               if(this.p3Weight){
                   this.headers = paperHeaders.map(value => {
                       if(value.text === 'Comments') value.width = 182;
                       return value;
                   });
                   
                   //console.log(this.headers);
               }
               else{
                   this.headers = paperHeaders.filter(value => (value.paper !== 3))
               }
           }
           else if(this.lessonSelected.formLevel == 6 && this.currentTerm == 1) {
               this.headers = [
                    {text: '', value: 'count', align: 'left', width: '45', sortable: false},
                    {text: 'Class', value: 'class_id', width: '65', sortable: false},
                    {text: 'Name', value: 'name', width: '180', sortable: false},
                    {text: 'Course', value: 'course', align: 'center', width: '100', sortable: false,},
                    {text: 'Comments', value: 'custom_comment', sortable: false},            
                    // {text: 'Exam', value: 'exam', align: 'center', width: '65', sortable: false,},                    
                    // {text: 'Coded Comment 1', value: 'codedComment1', align: 'center', width: '100', sortable: false,},            
                    // {text: 'Coded Comment 2',  value: 'codedComment2', align: 'center', width: '100', sortable: false,},
                    {text: ' ', value: 'actions', align: 'center', sortable: false, width: '40'},                        
                ]    
           }       
       }
    }
}
</script>

<style scoped>
    .v-text-field__slot textarea{
        font-size: 13px;
    }
    .v-data-table > .v-data-table__wrapper > table{
        table-layout: fixed;
    }
    th span{
        display: flex;
    }
    ::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
        padding: 0 6px;
    }
</style>
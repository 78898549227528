<template>
    <v-dialog
        v-model="dialog"
        width="700"
        persistent
    >
        <v-card>
            <v-card-title class="text-h6 primary white--text">
                Update Term Reports
                <v-spacer></v-spacer>
                <v-btn
                    dark
                    color="primary lighten-2"
                    small
                    icon
                    style="position:absolute; top:10px; right:10px; z-index:100"
                    @click="close"
                >
                    <v-icon color="white">mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text v-if="!updating" class="py-4 text-h6">
                Are you sure you want to update student reports attendance?
            </v-card-text>

            <v-card-text v-else class="py-4">
                <v-row>
                    <v-col>
                        <v-progress-linear
                            color="primary"
                            buffer-value="0"
                            :value="progress"
                            stream
                            height="10"
                        ></v-progress-linear>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4">{{ updateMessage }}</v-col>
                    <v-col cols="8" class="text-right">
                        <span class="mr-4">Progress: {{ progress }} %</span> 
                        <span class="mr-4">Records: {{ recordsUpdated }} / {{ totalStudentsRegistered }}</span>
                        <span>Time Elapsed: {{ timeElapsed }}</span>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions v-if="!updating">
                <v-spacer></v-spacer>
                <v-btn outlined class="mr-4" @click="close">Cancel</v-btn>
                <v-btn color="primary" dark @click="update">Yes Update Reports</v-btn>
            </v-card-actions>

            <v-overlay
                absolute
                :value="overlay"                    
                class="pa-5"
            > 
                <v-progress-circular
                    indeterminate 
                    size="64"                            
                    color="primary"                                
                ></v-progress-circular>                   
            </v-overlay>

            <v-snackbar
                v-model="snackbar.show"
                :color="snackbar.color"
                timeout="2000"
            >
                {{ snackbar.text}}
                <template
                    v-slot:action="{ attrs }"
                >
                    <v-btn color="white" text v-bind="attrs" @click="snackbar.show = false">Close</v-btn>
                </template>
            </v-snackbar>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
export default {
    props: {
        initialDialog: Boolean,
    },

    watch: {
        initialDialog (val) {
            this.dialog = val;
            this.overlay = true;
            if(val) {
                this.initialize();
                return;
            }
        }
    },

    data () {
        return {
            dialog: false,
            headers:[
                { text: 'Year', value: 'year', width: '100' },
                { text: 'Term', value: 'term', width: '100' },
                { text: 'Start Date', value: 'term_start' },
                { text: 'End Date', value: 'term_end', sortable: false },
                { text: 'Actions', value: 'actions'}
            ],
            terms: [],
            loading: false,
            menuTermStart: false,
            overlay: false,
            snackbar: {
                text: null,
                color: null,
                show: false,
            },
            studentsRegistered: [],
            updating: false,
            progress: 0,
            totalStudentsRegistered: 0,
            recordsUpdated: 0,
            updateMessage: null,
            timeElapsed: null,
            startTime: null,
        }
    },
    
    methods: {
        ...mapActions({
            getStudentsRegistered: 'admin/getStudentsRegistered',
            postStudentReportAttendance: 'admin/postStudentReportAttendance',
        }),

        ...mapMutations({
            setStudentSelected: 'admin/setStudentSelected',         
        }),

        async initialize () {
            this.dialog = true; 
            try {
                const { data: { students, total } } = await this.getStudentsRegistered();
                this.studentsRegistered = students;
                this.totalStudentsRegistered = total;
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error)
            }
            this.overlay = false;
        },


        close () {
            this.dialog = false;
            this.updating = false;
            this.progress = 0;
            this.numberOfStudents = 0;
            this.$emit('set-dialog', false)
        },

        async update () {
            this.updating = true;
            this.updateMessage = 'Updating please wait...'
            //  const startTime = Date.now();
            this.startTime = Date.now();
            const timerInterval = setInterval(this.updateTimer, 1000);
            this.recordsUpdated = 0;
            try {
                for(const student of this.studentsRegistered){
                    this.setStudentSelected(student.student_id);
                    await this.postStudentReportAttendance();
                    this.recordsUpdated++;
                    this.progress = Math.round((this.recordsUpdated/this.totalStudentsRegistered)*100);
                }
                this.updateMessage = 'Update complete.'
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.updateMessage = 'An error has occured.'
            }
            clearInterval(timerInterval)
        },

        updateTimer () {
            const currentTime = Date.now();
            const elapsedTime = currentTime - this.startTime;
            const hours = Math.floor(elapsedTime / 3600000);
            const minutes = Math.floor((elapsedTime % 3600000) / 60000);
            const seconds = Math.floor((elapsedTime % 60000) / 1000);
            // Format the time with leading zeros
            this.timeElapsed = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
            // console.log(`${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`)
        }


    }
}
</script>
<template>
     <v-row>
        <v-col cols="12">
            <v-autocomplete
                v-model="selectedClassId"
                :items="formClasses"
                label="Select Class"
                @change="setClassId"
            ></v-autocomplete>
        </v-col>
        <v-col
            cols="12"
        >
            <date-picker-range
                label="Select Date Range"
                :selectedDate="dateRange"
                v-on:update-range="setDateRange"
            ></date-picker-range>
        </v-col>
    </v-row>
</template>

<script>
import DatePickerRange from './DatePickerRange'
export default {
    props: {
        formClasses: {
            type: Array,
            default: () => []
        }
    },

    components: {
        DatePickerRange,
    },

    data: () => ({
        dateRange: [],
        selectedClassId: null,
    }),

    methods: {
        setDateRange (dates) {
            this.dateRange = dates;
            if(dates.length == 1) this.dateRange = [
                dates[0],
                dates[0]
            ]
            this.$emit('set-date-range', this.dateRange);            
        },

        setClassId () {
            this.$emit('set-class-id', this.selectedClassId)
        }
    }
}
</script>
<template>
    <tr>
        <td style="position:sticky; left:0; top:0; z-index:3; background: white;">
            {{ this.record.number }}
        </td>

        <td style="position:sticky; left:50px; top:100; z-index:3; background: white; border-right: thin solid rgba(0, 0, 0, 0.12)">
            {{ this.record.name }}
        </td>

        <td></td>
       
        <td
            v-for="(item, i) in record.marks"
            :key="i"
        >
            <mark-book-row-cell
                v-bind:initial-mark="item"
                v-bind:assesment="assesments[i]"
                v-bind:assesment-number="i"
                v-bind:initial-mark-record="markRecord"
                v-on:display-dialog="displayDialog"
                v-on:update-mark="updateMark"
            ></mark-book-row-cell>
        </td>

        <td>
            <v-text-field
                outlined
                dense
                hide-details
                disabled
                filled
                v-model="total"
                class="font-weight-bold"
                type="number"
            ></v-text-field>
        </td>
        <td></td>
    </tr>
</template>

<script>
import MarkBookRowCell from './MarkBookRowCell.vue'
export default {
    components: {
        MarkBookRowCell
    },

    props: {
        initialRecord: Object,
        markBookPage: Object,
        assesments: Array,
        updateRow: Boolean,
    },

    created: function () {
        this.initialize();
    },

    watch: {
        updateRow: function (val) {
            if(val){
                this.initialize();
            }
        }
    },

    data: function () {
        return {
            record: this.initialRecord,
            markRecord: {},
            marksSaveStatus: [],
        }
        
    },

    computed: {
        total () {
            let sumTotal = 0; 
            let markTotal = 0;
            this.assesments.forEach((value, index) => {
                if(value && value.total && this.record.marks[index]){
                    markTotal += parseInt(this.record.marks[index]);
                    sumTotal += value.total;
                }
            })

            if(isNaN((markTotal/sumTotal)*100)) return null;

            return ((markTotal/sumTotal)*100).toFixed(1);
        }
    },

    methods: {
        initialize () {
            this.markRecord = {
                employee_id: this.markBookPage.employee_id,
                form_class_id: this.markBookPage.form_class_id,
                subject_id: this.markBookPage.subject_id,
                student_id: this.record.id,
                term: this.markBookPage.term,
                academic_year_id: this.markBookPage.academic_year_id,
            };
        },

        updateMark ({ mark, assignment_number }) {
            this.record.marks[assignment_number] = mark;
        },

        displayDialog (msg) {
            this.$emit('display-dialog', msg)
        }
    }
}
</script>

<style scoped>
    /* Chrome, Safari, Edge, Opera */
    ::v-deep input::-webkit-outer-spin-button,
    ::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    text-align:center
    }

    /* Firefox */
    ::v-deep input[type=number] {
    -moz-appearance: textfield;
    text-align: center;
    }

</style>


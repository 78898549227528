<template>
    <div style="position:relative; width:100px; ">
        <Doughnut
            :chart-data="chartData"
            :chart-options="chartOptions"
            :plugins="plugins"
        ></Doughnut>
    </div>
</template>

<script>
import { Doughnut } from 'vue-chartjs/legacy';
// import { Chart as ChartJS, Title, Tooltip, Legend} from 'chart.js'

// ChartJS.register(Title, Tooltip, Legend);

import { Chart, ArcElement,  Tooltip } from 'chart.js';

Chart.register(ArcElement, Tooltip);

export default {
    components: {
        Doughnut
    },

    props: {
        chartData: {
            type: Object,
            default: () => {}
        },

        chartOptions: {
            type: Object,
            default: () => {}
        },
    },

    mounted () {
        //
    },
    
    data () {
        return {
            plugins: [
                {
                    id: 'centertext',
                    // beforeDraw: function(chart) {
                    //     // console.log(chart.config.options);
                    //     let text = chart.config.options.text
                    //     var width = chart.width,
                    //         height = chart.height,
                    //         ctx = chart.ctx;

                    //     ctx.restore();
                    //     var fontSize = (height / 114).toFixed(2);
                    //     ctx.font = fontSize + "em sans-serif";
                    //     ctx.textBaseline = "middle";

                    //     // var text = 0,
                    //     var textX = Math.round((width - ctx.measureText(text).width) / 2),
                    //         textY = height / 2;

                    //     ctx.fillText(text, textX, textY);
                    //     ctx.save();
                    // }
                }
            ]
            
        }
    },

    methods: {
        //
    }
}
</script>

<style scoped>
    
</style>
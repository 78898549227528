<template>
    <v-card>
        <v-card-title>
            Upload Course Assesments
            <v-btn
                icon
                class="ml-auto"
                @click="close"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text>
            <v-container>
                <v-row>
                    <v-col>
                        <v-file-input
                            v-model="file"
                            show-size
                            label="Click to select file to upload."
                            @change="selectFile"
                        ></v-file-input>
                    </v-col>    
                </v-row>
                <v-row v-if="file">
                    <v-progress-linear
                        v-model="progress"
                        color="primary"
                        height="25"
                        reactive
                    >
                        <strong>{{ progress }} %</strong>
                    </v-progress-linear>
                </v-row>

                <v-alert v-if="message" border="left" :color="messageColor" dark>
                    {{ message }}
                </v-alert>

                <v-row>
                    <v-col class="primary--text">
                        <v-alert 
                            border="left" 
                            type="info"
                            dense
                            outlined
                        >
                            Please use the <strong>downloaded</strong> Course Assesments Sheet as the upload template.
                        </v-alert>
                    </v-col>
                    
                </v-row>

            </v-container>
        </v-card-text>
        <v-card-actions class="mx-6">
            <v-spacer></v-spacer>
            <v-btn
                outlined
                class="mr-2"
                color="primary"
                @click="close"
            >
                Cancel
            </v-btn>            
            <v-btn
                color="primary"
                dark
                @click="uploadFile"
            >Upload File</v-btn>
        </v-card-actions>
        <v-overlay
            :value="overlay"
            :absolute="true"
            color="#fff"
            opacity="0.9"
        >
            <v-progress-circular                        
                indeterminate
                size="64"
                color="primary"
            ></v-progress-circular>
        </v-overlay>
    </v-card>
</template>

<script>
import UploadCourseAssesmentService from "../services/UploadCourseAssesmentService";
export default {
    props: {
        markBookPage: Object,
    },

    data: function () {
        return {
            file: null,
            progress: 0,
            message: '',
            messageColor: '',
            overlay: false,
        }
    },

    methods: {
        selectFile (file) {
            this.progress = 0;
            this.file = file;
            this.message = null;
        },

        uploadFile () {            
            if(!this.file) {
                this.messageColor = "red"
                this.message = "Please select a file!";
                return;
            }

            this.message = "";
            this.loading = true;

            UploadCourseAssesmentService.upload(
                this.file,
                this.markBookPage.academic_year_id,
                this.markBookPage.term,
                this.markBookPage.subject_id,
                this.markBookPage.form_class_id,
                this.markBookPage.employee_id, 
                (event) => {
                this.progress = Math.round((100 * event.loaded) / event.total);
            })
                .then((response) => {
                    // this.expandFileSelect = false;
                    // this.loading = false;

                    // setTimeout(() => {
                    //     this.expandConfirmUpload = true;
                    //     this.uploadConfirmText = "Confirm Upload Students"
                    // }, 700);
                    
                    // this.students = response.data;
                    console.log(response);
                    this.$emit('update-mark-book');
                })
                .catch((error) => {
                    if(error.response) console.log(error.response);
                    else console.log(error);
                    this.progress = 0;
                    this.message = "Could not upload the file.";
                    this.messageColor = 'red'
                    this.file = null;
                })
        },

        close () {
            this.$emit('close-upload');
            this.file = null;
        }
    }
}
</script>
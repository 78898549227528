<template>
    <div>
        <div class="d-flex justify-center">
            <v-btn
                small
                color="primary"
                icon
                class="mb-2 mr-4"
                @click="deleteAssesment"
            >
                
                <v-icon
                v-if="item.index > defaultAssesments"
                    small
                    dark
                >
                    mdi-trash-can-outline
                </v-icon>
            </v-btn>
        </div>
        <v-menu
            style="top:-12px"
            offset-y
            :close-on-content-click="false"
            :close-on-click="false"
            v-model="menu"
        >
            <template
                v-slot:activator="{ on, attrs }"
            >
                
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    x-small
                    color="primary"
                    tile
                    class="mb-2"
                >
                    Edit
                    <v-icon
                        right
                        small
                        dark
                    >
                        mdi-pencil
                    </v-icon>
                </v-btn>
            </template>
            <v-card>
                <v-card-title
                    class="text-h6 secondary"
                >
                    Edit Assessment Details
                    <v-icon
                        right
                    >
                        mdi-pencil
                    </v-icon>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-textarea
                                v-model="assesment.topic"
                                label="Topic"
                                prepend-icon="mdi-text-long"
                                rows="2"
                                hide-details
                                clearable
                            ></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-menu
                                ref="menu"
                                v-model="menuDate"
                                :close-on-content-click="false"
                                :return-value.sync="assesment.date"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="assesment.date"
                                        label="Date"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        hide-details
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="assesment.date"
                                    no-title
                                    scrollable
                                >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="menu = false"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.menu.save(assesment.date)"
                                >
                                    OK
                                </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                   
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="assesment.total"
                                label="Total"
                                hide-details
                                prepend-icon="mdi-counter"
                                type="number"
                                @keypress="numberValidation" 
                            ></v-text-field>
                        </v-col>
                    </v-row>

                </v-card-text> 

                <v-card-actions>
                    <span
                        v-if="saveStatus"
                    >
                        <v-fade-transition leave-absolute>
                            <div v-if="saveState.saved">
                                <span class='text-caption primary--text'>Data Saved.</span>
                                <v-icon
                                    color="blue"
                                    x-small
                                    class="ml-2"
                                >
                                    mdi-check-all
                                </v-icon>
                            </div>

                            <div v-else-if="saveState.error">
                                <span class='text-caption red--text'>Error Occured</span>    
                                <v-icon
                                    v-if="saveState.error"
                                    small
                                    color="red"
                                    class="ml-2"                      
                                >
                                    mdi-alert-circle
                                </v-icon>
                            </div>

                            <div v-else-if="saveState.saving">
                                <span class='text-caption primary--text'>Saving</span>      
                                <v-progress-circular
                                    size="16"
                                    width="3"
                                    color="primary"
                                    indeterminate
                                    class="ml-2"
                                >
                                </v-progress-circular>
                            </div>         
                        </v-fade-transition>                        
                    </span>
                    <v-spacer></v-spacer>
                    <v-btn 
                        color="primary"
                        text
                        outlined
                        @click="closeMenu"
                    >
                        Close
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="save"
                    >
                        Save
                    </v-btn>
                </v-card-actions>
                
            </v-card>
        </v-menu>
        
        <div 
            style="width: 80px; line-height:1.5em; height: 3em; white-space: pre-line; overflow: hidden; word-wrap: break-word; display: -webkit-box; -webkit-box-orient:vertical; -webkit-line-clamp: 2"
        >
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <span
                        v-bind="attrs"
                        v-on="on"
                    >
                        {{ displayTopic }}
                    </span>
                </template>
                <span>{{ displayTopic }}</span>
            </v-tooltip>    
            
        </div>
        <div>{{ displayDate }}</div>
        <div>Total: {{ displayTotal }}</div>
    </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { format, parseISO } from 'date-fns';
export default {
    props: {
        item: Object,
        markBookPage: Object,
        assesmentNumber: Number,
    },

    mounted: function () {
        this.initialize();
    },

    data: () => ({
        menu: false,
        menuDate: false,
        assesment: {},
        saveStatus: true,
        saveState: {
            saved: false,
            saving: false,
            error: false,
        },
        defaultAssesments: 5,
    }),

    computed: {
        displayDate () {
            return this.assesment.date ? format(parseISO(this.assesment.date),'dd MMM yy') : 'Date: -'; 
        },

        displayTopic () {
            return this.assesment.topic || 'Topic: -';
        },

        displayTotal () {
            return this.assesment.total || '-'
        }
    },

    methods: {
        initialize () {
            // console.log(this.item);
            this.assesment = {...this.item};
        },

        ...mapMutations({
            setAssesment: 'markBook/setMarkBookAssesment',
            setDeleteAssesment: 'markBook/setDeleteAssesment',
        }),

        ...mapActions({
            postAssesment: 'markBook/postMarkBookAssesment',
        }),

        async save () {            
            try {
                this.setSaveStatus('saving');
                this.updateAssesment();
                this.setAssesment(this.assesment);
                const { statusText } = await this.postAssesment();
                console.log(statusText);
                this.$emit('update-assesments', this.assesment);
                this.setSaveStatus('saved');
            } catch (error) {   
                if(error.response) console.log(error.response);
                else console.log(error);
                this.setSaveStatus('error');
            }
        },

        updateAssesment () {
            this.assesment.assesment_number = this.assesmentNumber;
            this.assesment.academic_year_id = this.markBookPage.academic_year_id;
            this.assesment.employee_id = this.markBookPage.employee_id;
            this.assesment.form_class_id = this.markBookPage.form_class_id;
            this.assesment.form_level = this.markBookPage.form_level;
            this.assesment.subject_id = this.markBookPage.subject_id;
            this.assesment.term = this.markBookPage.term;
            this.assesment.weighting = this.markBookPage.weighting;
        },

        setSaveStatus (status = null) {
            Object.keys(this.saveState).forEach(state => {
                this.saveState[state] = false;
                if(state == status){
                    this.saveState[state] = true;
                }
            })
        },

        numberValidation ($event) {
            if($event.keyCode < 48 || $event.keyCode > 57)
            return $event.preventDefault();
        },

        closeMenu () {
            this.menu = false;
            this.setSaveStatus();
        },

        deleteAssesment () {
            // console.log(this.item);
            this.setDeleteAssesment(this.item);
            this.$emit('delete-dialog','Are you sure you want to delete this assessment?');
        }
    }
}
</script>
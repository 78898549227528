<template>
    <v-row
        justify="center"
    > 
        <v-col>        
            <v-card
                class="mx-auto pa-0"
                width="1080"               
            >
                <v-card-title
                    class="d-flex white--text  py-2 px-0 primary"
                >
                    <v-spacer></v-spacer>
                    <div class="caption font-weight-light text-uppercase">Mark Book</div>
                    <v-spacer></v-spacer>
                    <v-btn
                        text                
                        x-small
                        color="white"
                        @click="closeMarkSheet"
                    >
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn>            
                </v-card-title>
                <v-card-text class="pb-0">
                    <v-data-table
                        :headers="headers"
                        :items="students"
                        hide-default-footer
                        hide-default-header
                        height="60vh"
                        disable-pagination
                        :search="search"
                        fixed-header
                        class="assesment-table"
                    >
                        <template v-slot:top>
                            <v-toolbar 
                                flat
                                class="mb-4"
                            >
                                <v-toolbar-title>
                                    <div
                                        class="mt-2"
                                    >{{ lesson }}</div>
                                    <v-card
                                        width="200px"
                                        class="text-caption"
                                    >
                                        <v-select
                                            :items="markBookTerms"
                                            disabled
                                            filled
                                            
                                            v-model="academicTerm"
                                            dense
                                            hide-details
                                        ></v-select>
                                    </v-card>    
                                </v-toolbar-title>
                                
                                <v-spacer></v-spacer>
                                <v-text-field
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    label="Search for Student"
                                    single-line
                                    hide-details
                                    clearable
                                ></v-text-field>
                                <v-spacer></v-spacer>
                                 
                                <v-dialog
                                    v-model="dialog"
                                    max-width="400px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            color="primary"
                                            dark
                                            class="mb-2 ml-4"
                                            v-bind="attrs"
                                            v-on="on"
                                            small
                                        >
                                             <v-icon 
                                                small
                                                dark
                                            >
                                                mdi-plus
                                            </v-icon>
                                            Add Assessment
                                        </v-btn>
                                    </template>
                                    <mark-book-new-assesment
                                        v-on:close-new-assesment="closeNewAssesment"
                                        v-on:add-assesment="updateMarkBook"
                                        v-bind:assesments="assesments"
                                        v-bind:dialog="dialog"
                                        v-bind:mark-book-page="markBookPage"
                                    ></mark-book-new-assesment>
                                </v-dialog>
                            </v-toolbar>
                        </template>

                        <template v-slot:header="{ props: { headers} }">
                            <thead >
                                <tr>
                                    <th
                                        v-for="(item, index) in headers"
                                        :key="index"
                                        :style="thStyle(item)"
                                    >
                                        <mark-book-assesment
                                            v-if="item.editable"
                                            v-bind:item="item"
                                            v-bind:mark-book-page="markBookPage"
                                            v-bind:assesment-number="index-marksOffset"
                                            v-on:delete-dialog="deleteDialog"
                                            v-on:update-assesments="updateAssesments"
                                        ></mark-book-assesment>
                                        
                                        <div v-else>
                                            <div class="text-h6">{{ item.text }}</div>
                                            <div class="text-caption">{{ item.subText }}</div>
                                        </div>

                                    </th>    
                                     
                                </tr>
                            </thead>
                        </template>

                        <template v-slot:item="props">
                            <mark-book-row
                                v-bind:initial-record="props.item"
                                v-bind:mark-book-page="markBookPage"
                                v-bind:assesments="assesments"
                                v-bind:update-row="updateRow"
                                v-on:display-dialog="displayDialog"
                            ></mark-book-row>
                        </template>

                        <template v-slot:footer="{ props: { pagination } }">
                            <div class="d-flex">
                                <v-btn
                                    color="primary"
                                    class="mb-2 mt-4"
                                    @click="upload"
                                    small
                                >
                                    <v-icon 
                                        dark
                                        small
                                        left
                                    >
                                        mdi-cloud-upload
                                    </v-icon>
                                    Upload
                                </v-btn>
                                <v-btn
                                    color="primary"
                                    class="mb-2 mt-4 ml-4"
                                    @click="download"
                                    small
                                >
                                    <v-icon 
                                        dark
                                        small
                                        left
                                    >
                                        mdi-download
                                    </v-icon>
                                    Download
                                </v-btn>
                                <v-spacer></v-spacer>
                                <span class="mt-4 primary--text font-weight-bold">
                                    Total Students: {{ pagination.itemsLength}}
                                </span>
                            </div>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-col>

        <v-overlay
            absolute
            :value="overlay"                    
            color="grey lighten-5"
            opacity="0.9"
            class="pa-5"
        >                    
            <Lesson
                v-if="displayLessons" 
                v-on:lesson-selected="lessonSelected"
                v-on:lessons-loaded="lessonsLoaded"
                v-on:display-teachers="displayTeachers"
                parent-component="mark-book"
                v-bind:employeeId="selectedEmployeeId"
                v-bind:spreadsheet-access="spreadsheetAccess"
            />

            <v-expand-transition>
                <v-card
                    class="mx-auto"
                    width="350"
                    v-show="expandSpreadsheetAccess"
                    light
                >
                    <v-toolbar
                        color="primary white--text"
                        light
                    >
                        <v-toolbar-title>Select Option</v-toolbar-title>
                    </v-toolbar>
                    <v-list>                
                        <v-list-item-group color="primary">
                            <template v-for="(option, i) in optionsSpreadsheetAccess">
                                <v-list-item                            
                                    :key="option.option"
                                    @click="displayAccess(option)"
                                >
                                    <v-list-item-content>
                                        <v-list-item-title v-text="option.title"></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider
                                    v-if="i < options.length - 1"
                                    :key="i"
                                >                        
                                </v-divider>
                            </template>
                            
                        </v-list-item-group>
                    </v-list>
                </v-card>
            </v-expand-transition>

            <!-- admin options -->
            <v-expand-transition>
                <v-card
                    class="mx-auto"
                    width="350"
                    v-show="expandOptions"
                    light
                >
                    <v-toolbar
                        color="primary white--text"
                        light
                    >
                        <v-toolbar-title>Select Option</v-toolbar-title>
                    </v-toolbar>
                    <v-list>                
                        <v-list-item-group color="primary">
                            <template v-for="(option, i) in options">
                                <v-list-item                            
                                    :key="option.option"
                                    @click="displayOption(option)"
                                >
                                    <v-list-item-content>
                                        <v-list-item-title v-text="option.title"></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider
                                    v-if="i < options.length - 1"
                                    :key="i"
                                >                        
                                </v-divider>
                            </template>
                            
                        </v-list-item-group>
                    </v-list>
                </v-card>
            </v-expand-transition>

            <!-- select teacher-->
            <v-expand-transition>
                <v-card
                    class="mx-auto"
                    width="500"            
                    v-show="options[0].expand"
                    light                        
                >    
                    
                    <v-toolbar
                        color="primary white--text"
                        light
                    >
                        <v-toolbar-title>
                            <v-btn
                                text
                                dark
                                outlined
                                small
                                class="mr-4"
                                @click="backToOptions"
                            >
                                <v-icon class="mr-2">mdi-arrow-left</v-icon>
                                <div>Back</div>
                            </v-btn>
                            <span>Select Teacher</span>
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-card-title>
                        <v-text-field
                            v-model="searchTeacher"
                            append-icon="mdi-magnify"
                            label="Search Teacher"
                            single-line
                            hide-details
                            clearable
                        ></v-text-field>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table
                            :headers="headersEmployees"
                            :items="employees"
                            :search="searchTeacher"
                            hide-default-header
                            height="40vh"
                        >
                            <template
                                v-slot:[`item.actions`]="{ item }"
                            >
                                <v-btn
                                    @click="selectTeacher(item)"
                                    color="primary"
                                    outlined
                                >Select</v-btn>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-expand-transition>

            <!-- spreadsheet options -->
            <v-expand-transition>
                <v-card
                    class="mx-auto"
                    width="400"            
                    v-show="options[1].expand"
                    light                        
                >    
                    
                    <v-toolbar
                        color="primary white--text"
                        light
                    >
                        <v-toolbar-title>
                            <v-btn
                                text
                                dark
                                outlined
                                small
                                class="mr-4"
                                @click="backToOptions"
                            >
                                <v-icon class="mr-2">mdi-arrow-left</v-icon>
                                <div>Back</div>
                            </v-btn>
                            <span>Mark Book Spreadsheet </span>
                        </v-toolbar-title>
                    </v-toolbar>
                    
                    <v-card-text class="px-8">
                        <v-row>
                            <v-col>
                                <v-select
                                        v-model="spreadsheet.formLevel"
                                        :items="formLevels"
                                        label="Select Form Level"
                                        chips                                        
                                        hide-details
                                        @change="setFormClasses"                                       
                                    >
                                        <template v-slot:selection="data">
                                            <v-chip
                                                v-bind="data.attrs"
                                                :input-value="data.selected"
                                                close
                                                @click:close="clearFormLevel"                                                
                                            >
                                                {{ data.item}}
                                            </v-chip>
                                        </template>
                                    </v-select>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                                <v-autocomplete
                                        v-model="spreadsheet.formClass"
                                        :items="formClasses"
                                        label="Select Form Class"
                                        chips
                                        deletable-chips                                        
                                        hide-details
                                        @change="setFormClasses" 
                                        item-text="id"
                                        item-value="id"                                      
                                    >
                                    </v-autocomplete>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                                <v-autocomplete
                                    v-model="spreadsheet.subjectId"
                                    :items="subjects"
                                    item-text="title"
                                    item-value="id"
                                    label="Select Subject"
                                    chips
                                    deletable-chips
                                ></v-autocomplete>
                            </v-col>
                        </v-row>

                    </v-card-text>

                    <v-card-actions class="px-8 pb-8">
                        <v-btn
                            color="primary"
                            block
                            @click="displaySpreadsheet"
                        >
                            Download Spreadsheet
                            <v-icon
                                right
                                dark
                            >
                                mdi-cloud-download-outline
                            </v-icon>
                        </v-btn>
                    </v-card-actions>

                    <v-overlay
                        v-if="overlaySpreadsheet"
                        absolute
                        opacity="0.7"
                    >
                        <div 
                            class="d-flex flex-column justify-center align-center"
                        >    
                            <!-- <v-progress-circular 
                                transition="fade-transition" 
                                indeterminate 
                                size="64"                            
                                color="primary"
                            ></v-progress-circular> -->
                            <v-card width="250" light>
                            <v-progress-linear
                                :value="progress"
                                height="25"
                                stream
                                buffer-value="10"
                                color="primary"
                            >
                                <template v-slot:default="{ value }">
                                    <strong>
                                        {{ Math.ceil(value) }} %
                                    </strong>
                                </template>
                            </v-progress-linear>
                            </v-card>
                            <div 
                                class="white--text mt-4"
                            >{{ progressText }}</div>
                        </div> 
                    </v-overlay>
                </v-card>
            </v-expand-transition>

            <div 
                class="d-flex flex-column justify-center align-center"
                v-if="loading"
            >    
                <v-progress-circular 
                    transition="fade-transition" 
                    indeterminate 
                    size="64"                            
                    color="primary"
                ></v-progress-circular>
                <div 
                    class="primary--text mt-4"
                >{{ progressText }}</div>
            </div> 
        </v-overlay>
        <!-- upload assessment dialog -->
        <v-dialog
            v-model="dialogUpload"
            max-width="500px"
            persistent
        >
            <v-expand-transition>
                <mark-book-upload
                    v-show="expandUpload"
                    v-on:close-upload="closeUpload"
                    v-on:update-mark-book="updateMarkBook"
                    v-bind:mark-book-page="markBookPage"
                ></mark-book-upload>
            </v-expand-transition>
        </v-dialog>
        <!-- message dialog -->
        <v-dialog
            v-model="dialogMessage"
            width="500"
        >
            <v-card>
                <v-card-title class="text-h5 secondary">
                    <v-icon color="orange">mdi-alert</v-icon>
                    <span class="orange--text ml-2">Warning</span>
                </v-card-title>
                <v-card-text class="text-h6">
                    {{ dialogContent.message }}
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="!deleteAction"
                        color="primary"
                        @click="dialogMessage = false"
                        outlined
                    >
                        Ok
                    </v-btn>
                    <v-btn
                        v-if="deleteAction"
                        outlined 
                        @click="dialogMessage = false"                       
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        v-if="deleteAction"
                        color="primary"
                        @click="confirmDelete"
                    >
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-row>        
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Lesson from './EnterMarksLesson';
import MarkBookUpload from './MarkBookUpload.vue';
import MarkBookNewAssesment from './MarkBookNewAssesment.vue';
import MarkBookAssesment from './MarkBookAssesment';
import MarkBookRow from './MarkBookRow.vue';
import { format, parseISO } from 'date-fns';

export default {
    name: 'MarkBook',

    components: {
        Lesson,
        MarkBookUpload,
        MarkBookNewAssesment,
        MarkBookAssesment,
        MarkBookRow
    },

    created: function () {
        this.initialize();
    },

    data: () => ({
        headers:[],
        students: [],
        lesson: '',
        dialog: false,
        dialogMessage: false,
        dialogContent: {},
        dialogUpload: false,
        search: '',
        overlay: false,
        loading: false,
        markBookPage: {},
        marksOffset: 2,
        defaultAssesments: 5,
        assesments: [],
        markBookTerms: [],
        academicTerm: null,
        src: '',
        // expandTeachers: false,
        searchTeacher: '',
        headersEmployees: [
            { text: 'Name', value: 'name' },
            { text: 'Actions', value: 'actions' }
        ],
        employees: [],
        selectedEmployeeId: null,
        displayLessons: false,
        expandOptions: false,
        options: [
            { option: 1, title: 'Edit/View Teacher Mark Book', value: 'teachers', expand: false},
            { option: 2, title: 'Course Assessments Spreadsheet', value: 'reports', expand: false},
        ],
        formLevels: [],
        formClasses: [],
        formClassesRecords: [],
        subjects: [],
        selectedSubject: null,
        spreadsheet: {
            year: null,
            term: null,
            formLevel: null,
            formClass: 0,
            subjectId: null,
        },
        overlaySpreadsheet: false,
        academicTerms: [],
        menuDate: false,
        assesment: {},
        saveStatus: true,
        saveState: {
            saved: false,
            saving: false,
            error: false,
        },
        updateRow: false,
        expandUpload: false,
        spreadsheetAccess: false,
        spreadsheetPostAccess: [1,2,4,5,6,19,20,21],
        expandSpreadsheetAccess: false,
        optionsSpreadsheetAccess: [
            { option: 1, title: 'Edit/View Mark Book', value: 'markBook', expand: false},
            { option: 2, title: 'Course Assessments Spreadsheet', value: 'reports', expand: false},
        ],
        deleteAction: false,
        progress: 0,
        progressText: '',
    }),

    computed: {
        ...mapGetters({
            employeeId: 'auth/getEmployeeId',
            employeePost: 'auth/getEmployeePost',
            academicYearId: 'termReports/getAcademicYearId',
            term: 'termReports/getCurrentTerm',
            admin: 'auth/getAdmin',
        }),        
    },

    methods: {
        initialize () {
            this.loading = true;
            this.overlay = true;
            if(this.admin){
                this.setAdminOptions();
            }
            else if(this.spreadsheetPostAccess.includes(this.employeePost)){
                this.setSpreadsheetAccess();
                this.spreadsheetAccess = true;
            }
            else{
                this.displayLessons = true;
            }            
        },

        ...mapMutations({
            setExpand: 'termReports/setExpandLessonOptions',
            setMarkBookPage: 'markBook/setMarkBookPage',
            setSpreadsheet: 'markBook/setSpreadsheet',
            setFile: 'markBook/setFile',
        }),

        ...mapActions({
            getMarkBook: 'markBook/getMarkBook',
            downloadExcelMarkBookPage: 'markBook/downloadExcelMarkBookPage',
            getEmployees: 'admin/getEmployees',
            getFormLevels: 'admin/getFormLevels',
            getFormClasses: 'admin/getFormClasses',
            getSubjects: 'admin/getSubjects',
            getTerms: 'markBook/getAcademicTerms',
            deleteCourseAssesment: 'markBook/deleteCourseAssesment', 
            postSpreadsheet: 'markBook/postSpreadsheet',
        }),

        async setAdminOptions () {
            this.loading = true;
            try {
                const formLevelsPromise = this.getFormLevels();
                const employeesPromise = this.getEmployees();
                const formClassesPromise = this.getFormClasses();
                const subjectsPromise = this.getSubjects();
                const promiseTerms = this.getTerms();

                const [ 
                    { data: dataEmployees }, 
                    { data: dataFormLevels },
                    { data: dataFormClasses },
                    { data: dataSubjects },
                    { data: dataTerms }
                ] =
                await Promise.all([
                    employeesPromise,
                    formLevelsPromise,
                    formClassesPromise,
                    subjectsPromise,
                    promiseTerms
                ]);
                
                this.mapFormLevels(dataFormLevels);
                this.mapFormClasses(dataFormClasses);
                this.mapEmployees(dataEmployees);
                this.mapSubjects(dataSubjects);
                this.academicTerms = dataTerms;
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
            this.loading = false;
            this.$nextTick(() => {
                this.expandOptions = true;
            })
        },

        async setSpreadsheetAccess () {
            try {
                const formLevelsPromise = this.getFormLevels();
                const formClassesPromise = this.getFormClasses();
                const subjectsPromise = this.getSubjects();
                const promiseTerms = this.getTerms();

                 const [ 
                    { data: dataFormLevels },
                    { data: dataFormClasses },
                    { data: dataSubjects },
                    { data: dataTerms }
                ] =
                await Promise.all([
                    formLevelsPromise,
                    formClassesPromise,
                    subjectsPromise,
                    promiseTerms
                ]);
                
                this.mapFormLevels(dataFormLevels);
                this.mapFormClasses(dataFormClasses);
                this.mapSubjects(dataSubjects);
                this.academicTerms = dataTerms;
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
            
            this.loading = false;
            setTimeout(() => {
                this.expandSpreadsheetAccess = true;
            }, 700);            
        },

        displayAccess (option) {
            console.log(option);
            if(option.value == 'markBook'){
                this.expandSpreadsheetAccess = false;
                setTimeout(() => {
                    this.displayLessons = true;
                    setTimeout(() => {
                        this.setExpand(true);
                    }, 700);
                    
                })
            }
            else if(option.value == 'reports'){
                this.expandSpreadsheetAccess = false;
                setTimeout(() => {
                    this.options[1].expand = true;
                }, 700)
            }
        },

        mapEmployees (data) {
            this.employees = data.map(value => {
                value.name = value.last_name + ' ' + value.first_name;
                    return value;
            })
        },

        mapFormClasses (data) {
            this.formClassesRecords = data;
            data.forEach(value => {
                if(value.form_level){
                    this.formClasses.push(value.id);
                }
            })
        },

        mapFormLevels (data) {
            data.forEach(value => {
                this.formLevels.push(value.form_level);
            })
        },

        mapSubjects (data) {
            this.subjects = data;
        },

        lessonSelected (lesson) {
            this.lesson = lesson.lesson;
            console.log('lesson selected');
            let employeeId = this.selectedEmployeeId || this.employeeId
            
            this.markBookPage = {
                form_class_id: lesson.className,
                form_level: lesson.formLevel,
                subject_id: lesson.subjectCode,
                employee_id: employeeId,
                academic_year_id: this.academicYearId,
                term: this.term,
            };

            let academicTerm = this.academicYearId.toString().substr(0, 4)
            + '-' + this.academicYearId.toString().substring(4)
            + ' Term ' + this.term;

            this.markBookTerms.push({
                text: academicTerm,
            })

            this.academicTerm = academicTerm;
            
            this.setMarkBookPage(this.markBookPage);
            
            setTimeout(() => {
                this.initializeMarkBook();
            }, 700)
        },

        async initializeMarkBook () {
            this.displayLessons = false;
            setTimeout(() => {
                this.loading = true;
            }, 700);
            this.updateRow = false;
            this.students = [];
            this.headers = [];
            this.assesments = [];
           
            try {
                const { data: { assesments, students } } 
                = await this.getMarkBook();
                this.mapHeaders(assesments);

                this.students = students.map((value, index) => {
                    value.name = value.last_name + ', ' + value.first_name;
                    value.number = index+1;
                    return value;
                });

                this.updateRow = true;
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
            this.loading = false;
            this.overlay = false;
        },

        mapHeaders (assesments) {
            let assesmentCount = this.defaultAssesments;
            
            this.headers = [ 
                {
                    text: '#',
                    value: 'number'
                },            
                {
                    text: 'Name',
                    value: 'name',
                    width: '300px'
                },
                {
                    text: '',
                    value: 'blank'
                }
            ];

            assesments.forEach(value => {
                if(value.assesment_number > assesmentCount){
                    assesmentCount = value.assesment_number;
                }
            })
            console.log(assesments);
            for(let i = 1; i <= assesmentCount; i++){
                let date = null;
                let topic = null;
                let total = null;
                let assesment = null;
                let id = null;

                assesments.forEach(value => {
                    if(value.assesment_number == i){
                        topic = value.topic || null;
                        date = value.date ? 
                        format(parseISO(value.date), 'yyyy-MM-dd') : null;
                        
                        total = value.total || null;
                        id = value.id
                    }
                })

                assesment = {
                    editable: true,
                    topic: topic,
                    date: date,
                    total: total,
                    index: i,
                    id: id
                }

                this.headers.push(assesment);
                this.assesments[i] = assesment;
            }
            this.headers.push({
                text: 'Total %',
                subText: 'Unweighted'
            }),
            this.headers.push({
                text: '',
            })
           
        },

        thStyle (item){
            if(item.editable) return 'width:100px;';
            if(item.text == '#') return 'width:50px; position:sticky; left:0; z-index:4;';
            if(item.text == 'Name') return 'width:200px; position:sticky; left:50px; z-index:4; border-right: thin solid rgba(0, 0, 0, 0.12)';
            if(item.text == 'Total %') return 'width:90px';
            if(item.value == 'blank') return 'width:20px';
        },

        closeMarkSheet () {
            console.log('close mark sheet');
            this.headers = [];
            this.students = [];
            this.assesments = [];
            this.overlay = true;
            this.displayLessons = true;
        },

        updateAssesments ({ 
            assesment_number, 
            date, 
            topic, 
            total, 
            weighting
        }) {
            this.assesments[assesment_number].date = date;
            this.assesments[assesment_number].topic = topic;
            this.assesments[assesment_number].total = total;
            this.assesments[assesment_number].weighting = weighting;
            
            this.loading = true;
            this.overlay = true;
            this.initializeMarkBook();
        },

        displayDialog (message) {
            this.dialogContent.message = message;
            this.dialogMessage = true;
        },

        deleteDialog (message) {
            this.dialogContent.message = message;
            this.dialogMessage = true;
            this.deleteAction = true;
        },

        download () {
            this.loading = true;
            this.displayLessons = false;
            this.overlay = true;
            let timestamp = format(new Date(), 'yyyyMMddhhmmss');
            let filename = this.markBookPage.form_class_id + 
            ' Term ' + this.markBookPage.term + ' Course Marks ' + timestamp +'.xlsx';
            console.log(this.markBookPage);

            this.downloadExcelMarkBookPage()
            .then(response => {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                link.setAttribute('download', filename);
               
                document.body.appendChild(link);
                link.click();

                this.overlay = false;
                this.loading = false;
            })
            .catch( error => {
                if(error.response){
                    console.log(error.response);
                } 
                else{
                    console.log(error);
                } 

                this.overlay = false;
                this.loading = false;

                let message = 'An Error has occured, file could not be downloaded.'
                this.displayDialog(message);
            })
        },

        selectTeacher (item) {
            this.selectedEmployeeId = item.id;
            // this.expandTeachers = false;
            this.options.forEach(option => {
                if(option.value == 'teachers'){
                    option.expand = false;
                }
            })
            setTimeout(() => {
                this.displayLessons = true;
            }, 700)
            
        },

        lessonsLoaded () {
            this.loading = false;
        },

        displayTeachers () {
            console.log('display teachers');
            if(this.admin){
                this.displayLessons = false;
                this.options.forEach(option => {
                    if(option.value == 'teachers'){
                        option.expand = true;
                    }
                })
            }
            else{
                setTimeout(() => {
                    this.expandSpreadsheetAccess = true;
                }, 700); 
            }
            
        },

        displayOption ({ value }) {
            switch (value) {
                case 'teachers':
                    this.expandOptions = false;
                    setTimeout(() => {
                        this.options.forEach(option => {
                            if(option.value == 'teachers'){
                                option.expand = true;
                            }
                        })
                    }, 700);
                    break;
                case 'reports':
                    this.expandOptions = false;
                    setTimeout(() => {
                        this.options.forEach(option => {
                            if(option.value == 'reports'){
                                option.expand = true;
                            }
                        })
                    }, 700)

            }
        },

        backToOptions () {
            console.log('back to options');
            console.log(this.admin);
            this.options.forEach(value => {
                value.expand = false;
            });

            if(this.admin){
                setTimeout(() => {
                    this.expandOptions = true;
                }, 700)
            }
            else{
                setTimeout(() => {
                    this.expandSpreadsheetAccess = true;
                }, 700); 
            }
        },

        setFormClasses () {
            if(this.spreadsheet.formLevel){
                this.formClasses = [];
                this.formClassesRecords.filter(record => {
                    if(record.form_level == this.spreadsheet.formLevel)
                    this.formClasses.push(record.id);
                })
            }
            else{
                console.log('no selected form level');
                this.spreadsheet.formLevel = 0;
            }
        },

        clearFormLevel () {
            this.spreadsheet.formLevel = 0;
            let formClasses = [];
            this.formClassesRecords.forEach(record => {
                if(record.form_level)
                formClasses.push(record.id);
            })
            this.formClasses = formClasses;
        },

        async displaySpreadsheet () {
            this.spreadsheet.year = this.academicYearId;
            this.spreadsheet.term = this.term;
            if(
                !this.spreadsheet.formLevel && 
                !this.spreadsheet.formClass && 
                !this.spreadsheet.subjectId
            ){
                console.log('all fields empty');
                this.displayDialog(
                    'No Fields selected. Please select field to download spreadsheet.'
                );
                return
            }

            this.overlaySpreadsheet = true;

            if(!this.spreadsheet.formLevel){
                this.spreadsheet.formLevel = 0;
            }

            if(!this.spreadsheet.formClass){
                this.spreadsheet.formClass = 0;
            }

            if(!this.spreadsheet.subjectId){
                this.spreadsheet.subjectId = 0;
            }

            this.setSpreadsheet(this.spreadsheet);

            let timestamp = format(new Date(), 'yyyyMMddhhmmss');
            let termName = this.termName(this.spreadsheet.term);
            let filename = this.spreadsheet.formClass + 
            ' ' + termName + ' Course Assesments ' + timestamp +'.xlsx';
            
            if(!this.spreadsheet.formClass){
                filename = 'Form ' + this.spreadsheet.formLevel + 
                ' ' + termName + ' Course Assesments ' + timestamp +'.xlsx';
            }

            // console.log(filename);

            let es = new EventSource(
                process.env.VUE_APP_API_URI +'/api/spreadsheet/' + 
                this.academicYearId + "/" +
                this.term + "/" + 
                this.spreadsheet.formLevel + "/" +
                this.spreadsheet.formClass + "/" +
                this.spreadsheet.subjectId
            );

            es.addEventListener('message', (e) => {
                let data = JSON.parse(e.data);
                this.progressText = data.message;
                this.progress = (data.progress/data.total)*100;
                if(data.complete){
                    es.close();
                    // this.overlaySpreadsheet = false;
                    
                    this.setFile({
                        filePath: data.filePath,
                        fileName: filename
                    });
                    // console.log(data.filePath);
                    this.downloadSpreadsheet(filename);
                } 
            })

            es.onerror = (e) => {
                console.log("error occured");
                if(e.readyState == EventSource.CLOSED){
                    console.log('Event was closed');
                    console.log(EventSource);
                }
                // console.log(e);
                this.overlaySpreadsheet = false;
                this.progress = 0;
                es.close();
                let message = 'An Error has occured, file could not be downloaded.'
                this.displayDialog(message);
            }

        },

        async downloadSpreadsheet (filename) {
            this.postSpreadsheet()
            .then(response => {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                link.setAttribute('download', filename);
               
                document.body.appendChild(link);
                link.click();

                this.overlaySpreadsheet = false;
                this.progress = 0;
            })
            .catch( error => {
                if(error.response){
                    console.log(error.response);
                } 
                else{
                    console.log(error);
                } 

                this.overlaySpreadsheet = false;
                this.progress = 0;

                let message = 'An Error has occured, file could not be downloaded.'
                this.displayDialog(message);
            })
        },

        termName (term) {
            let termName = '';
            this.academicTerms.forEach(value => {
                if(value.id == term){
                   termName = value.title
                }
            })
            console.log(termName);
            return termName;
        },

        closeNewAssesment () {
            this.dialog = false;
        },

        upload () {
            this.dialogUpload = true;
            this.expandUpload = true;
        },

        closeUpload () {
            this.expandUpload = false;
            setTimeout(() => {
                this.dialogUpload = false;
            }, 800);
        },

        updateMarkBook () {
            this.loading = true;
            this.overlay = true;
            this.initializeMarkBook();
        },

        async confirmDelete () {
            console.log('user confirm delete');
            this.dialogMessage = false;
            try {
                const response = await this.deleteCourseAssesment();
                console.log(response);
                this.initializeMarkBook();
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.deleteAction = false;
                this.dialogContent.message = "An Error has occured. Assessment could not deleted."
                this.dialogMessage = true;
            }
        }
        
    }
}
</script>

<style scoped>
    ::v-deep .assesment-table table{
        table-layout: fixed;
    }

    ::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
        padding: 0 4px 0 16px;
    }

    ::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
        padding: 0 4px 0 16px;
    }

    ::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th:nth-child(4){
        padding: 0 4px 0 16px;
    }

    ::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td:nth-child(4){
        padding: 0 4px 0 16px;
    }
</style>
<template>
    <v-dialog
        ref="dialog"
        v-model="modal"        
        persistent
        width="290px"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="dateRange"
                readonly
                v-bind="attrs"
                v-on="on"
                hide-details
                :label="label"
                clearable
                @click:clear="clearDate"                                  
            ></v-text-field>
        </template>
        <v-date-picker
            v-model="dates"
            range
        >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="cancel"
            >
                Cancel
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="saveDate"
            >
                OK
            </v-btn>
        </v-date-picker>
    </v-dialog>
</template>

<script>
export default {
    props: {
        label: {
            default: '',
            type: String,
        },
        selectedDate: Array,        
    },

    mounted: function () {
        this.dateRange = this.selectedDate.join(' - ');        
    },

    data: () => ({
        modal: false,
        dates: [],
        dateRange: '',
    }),

    watch: {
        dateRange: {
            handler (val) {
                if(!val) this.dates = [];
            }
        },

        // selectedDate: {
        //     handler (val) {
        //         console.log(val);
        //     }
        // }
    },

    methods: {
        saveDate () {
            this.$emit('update-range', this.dates);
            if(this.dates.length == 1){
                this.dates.push(this.dates[0]);
            }
            this.dateRange = this.dates.join(' - ');
            this.modal = false;
        },

        cancel () {
            this.modal = false;
            this.dates = [];
        }, 
        
        clearDate () {
            this.dates = [];
            this.$emit('update-range', this.dates);
        }
    }
}
</script>
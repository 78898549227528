// import axios from 'axios'

import axios from "axios"

export default{
    namespaced: true,

    state: {
        markBook: false,
        markBookPage: {},
        markBookAssesment: {},
        markRecord: {},
        spreadsheet: {},
        deleteAssesment: {},
        file: {},
    },

    getters: {
        getMarkBook (state) {
            return state.markBook
        },

        getMarkBookPage (state) {
            return state.markBookPage
        },

        getMarkRecord (state) {
            return state.markRecord
        },

        getMarkBookAssesment (state) {
            return state.markBookAssesment
        },

        getSpreadsheet (state) {
            return state.spreadsheet
        },

        getDeleteAssesment (state) {
            return state.deleteAssesment
        },

        getFile (state) {
            return state.file
        }
    },

    mutations: {
        setMarkBook (state, value) {
            state.markBook = value
        },

        setMarkBookPage (state, value) {
            state.markBookPage = value
        },

        setMarkRecord (state, value) {
            state.markRecord = value
        },

        setMarkBookAssesment (state, value) {
            state.markBookAssesment = value
        },

        setSpreadsheet (state, value) {
            state.spreadsheet = value
        }, 

        setDeleteAssesment (state, value) {
            state.deleteAssesment = value
        },

        setFile (state, value) {
            state.file = value
        }
    },

    actions: {
        getMarkBook ({ getters }) {
            let { 
                academic_year_id:year, 
                term, 
                subject_id:subjectId, 
                form_class_id:formClassId,
                form_level: formLevel, 
                employee_id:employeeId 
            } 
            = getters.getMarkBookPage;

            const url = '/api/mark-book/' +
            year + '/' +
            term + '/' +
            subjectId + '/' +
            formLevel + '/' +
            formClassId + '/' +
            employeeId;

            return axios.get(url);
        },

        postMarkRecord ({ getters }) {
            const url = '/api/mark-book';
            return axios.post(url, getters.getMarkRecord);
        },

        postMarkBookAssesment ({ getters }) {
            const url = '/api/mark-book-assesment';
            return axios.post(url, getters.getMarkBookAssesment);
        },

        downloadExcelMarkBookPage ({ getters }) {
            let { 
                academic_year_id:year, 
                term, 
                subject_id:subjectId, 
                form_class_id:formClassId,
                employee_id:employeeId 
            } 
            = getters.getMarkBookPage;

            const url = '/api/download-mark-book-page/' +
            year + "/" +
            term + "/" +
            formClassId + "/" +
            subjectId + "/" +
            employeeId;

            return axios({
                method: 'get',
                url: url,
                responseType: 'arraybuffer',
            });
        },

        getSpreadsheet ({ getters }) {
            let { 
                year, 
                term,
                formLevel,
                formClass,
                subjectId 
            } = getters.getSpreadsheet;

            const url = '/api/spreadsheet/' +
            year + "/" + term + "/" + formLevel +
            "/" + formClass + "/" + subjectId;
            console.log(url);
            return axios({
                method: 'get',
                url: url,
                responseType: 'arraybuffer',
            });
        },

        postSpreadsheet ({ getters }) {
            const url = '/api/spreadsheet-download';
            return axios({
                method: 'post',
                url: url,
                responseType: 'arraybuffer',
                data: getters.getFile
            })
        },

        getAcademicTerms () {
            const url = '/api/academic-terms';
            return axios.get(url);
        },

        deleteCourseAssesment ({ getters }) {
            const url = '/api/delete-course-assesment'
            return axios.post(url, getters.getDeleteAssesment);
        }
    }
}
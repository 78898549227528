import Vue from 'vue';
import Main from './components/layouts/Main';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  store,
  render: h => h(Main)
}).$mount('#app')

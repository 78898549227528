<template>
    
    <v-text-field
        v-model="mark"
        outlined
        dense
        hide-details
        type="number"
        @blur="save" 
        @keypress="numberValidation"                              
    >
        <template v-slot:append-outer>
            <v-fade-transition leave-absolute>
                <v-icon
                    v-if="markSaveStatus.saved"
                    color="blue"
                    x-small
                >
                    mdi-check-all
                </v-icon>
            </v-fade-transition>
            <v-fade-transition leave-absolute>    
                <v-icon
                    v-if="markSaveStatus.error"
                    small
                    color="red"                      
                >
                    mdi-alert-circle
                </v-icon>
            </v-fade-transition>
            <v-fade-transition leave-absolute>      
                <v-progress-circular
                    v-if="markSaveStatus.saving"
                    size="16"
                    width="3"
                    color="primary"
                    indeterminate
                >
                </v-progress-circular>
            </v-fade-transition>
        </template>
    </v-text-field>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
export default {
    props: {
        initialMark: {
            type:[String, Number],
            default: null
        },
        assesment: Object,
        assesmentNumber: String,
        markBookPage: Object,
        initialMarkRecord: Object,
    },

    data: function () {
        return {
            mark: this.initialMark,
            markRecord: this.initialMarkRecord,
            markSaveStatus: {
                saving: false,
                saved: false,
                error: false
            }
        }
        
    },

    methods: {
        ...mapMutations({
            setMarkRecord: 'markBook/setMarkRecord',
        }),

        ...mapActions({
            postMarkRecord: 'markBook/postMarkRecord',
        }),

        async save () {
            if(!this.markValidation()){                
                return;
            }
            this.updateMarkRecord();
            this.saveStatus('saving');
            this.setMarkRecord(this.markRecord);
            try {
                const { statusText } = await this.postMarkRecord();
                this.saveStatus('saved');
                console.log(statusText);
                this.$emit(
                    'update-mark',
                    {
                        mark: this.mark,
                        assignment_number: this.assesmentNumber
                    }
                )
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.saveStatus('error');
            }

        },

        updateMarkRecord () {
            this.markRecord.assesment_number = this.assesmentNumber;
            this.markRecord.mark = this.mark;
            this.markRecord.date = this.assesment.date;
            this.markRecord.topic = this.assesment.topic;
            this.markRecord.total = this.assesment.total;
        },

        saveStatus (status = null) {
            Object.keys(this.markSaveStatus).forEach(key => {
                this.markSaveStatus[key] = false;
                if(status && status == key){
                    this.markSaveStatus[key] = true;
                }                
            })
        },

        numberValidation ($event) {
            if($event.keyCode < 48 || $event.keyCode > 57)
            return $event.preventDefault();
        },

        markValidation () {
            console.log(`mark: ${this.mark}`);
            console.log(`initial mark: ${this.initialMark}`);
            if( 
                this.assesment && 
                this.mark && 
                !this.assesment.total
            ){
                this.$emit(
                    'display-dialog',
                    'Assesment has no Total. Total % will not be updated. ' + 
                    'Edit Assesment Total to calculate correct Total %'
                )
                return false;
            }
            else if(this.mark > this.assesment.total){
                this.$emit(
                    'display-dialog',
                    'Mark Entered is greater than the assigned total.'
                )
                this.saveStatus('error');
                return false;
            }
            if(this.mark == this.initialMark){
                return false;
            } 
            if(!this.mark && !this.initialMark){
                return false;
            } 
            return true;
        }

    }
}
</script>

<style scoped>
    /* Chrome, Safari, Edge, Opera */
    ::v-deep input::-webkit-outer-spin-button,
    ::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    text-align:center
    }

    /* Firefox */
    ::v-deep input[type=number] {
    -moz-appearance: textfield;
    text-align: center;
    }

    ::v-deep .v-input__control{
        width: 65px;
        flex-grow: 0;
    }

    ::v-deep .v-text-field .v-input__control{
        flex-grow: 0;
    }

    ::v-deep .v-text-field--outlined .v-input__control{
        flex-grow: 0;
        width: 65px;
    }
</style>
<template>
    <v-card>
        <v-card-title>
            New Assessment
        </v-card-title>
        <v-card-text>
             <v-row>
                <v-col>
                    <v-textarea
                        v-model="assesment.topic"
                        label="Topic"
                        prepend-icon="mdi-text-long"
                        rows="2"
                        hide-details
                        clearable
                    ></v-textarea>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        :return-value.sync="assesment.date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="assesment.date"
                                label="Date"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                hide-details
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="assesment.date"
                            no-title
                            scrollable
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            @click="menu = false"
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="$refs.menu.save(assesment.date)"
                        >
                            OK
                        </v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
           
            <v-row>
                <v-col>
                    <v-text-field
                        v-model="assesment.total"
                        label="Total"
                        hide-details
                        prepend-icon="mdi-counter"
                        type="number"
                        @keypress="numberValidation" 
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <span
                v-if="saveStatus"
            >
                <v-fade-transition leave-absolute>
                    <div v-if="saveState.saved">
                        <span class='text-caption primary--text'>Data Saved.</span>
                        <v-icon
                            color="blue"
                            x-small
                            class="ml-2"
                        >
                            mdi-check-all
                        </v-icon>
                    </div>

                    <div v-else-if="saveState.error">
                        <span class='text-caption red--text'>
                            {{ errorMessage }}
                        </span>    
                        <v-icon
                            v-if="saveState.error"
                            small
                            color="red"
                            class="ml-2"                      
                        >
                            mdi-alert-circle
                        </v-icon>
                    </div>

                    <div v-else-if="saveState.saving">
                        <span class='text-caption primary--text'>Saving</span>      
                        <v-progress-circular
                            size="16"
                            width="3"
                            color="primary"
                            indeterminate
                            class="ml-2"
                        >
                        </v-progress-circular>
                    </div>         
                </v-fade-transition>                        
            </span>
            <v-spacer></v-spacer>
            <v-btn
                outlined
                color="primary"
                @click="cancel"
            >
                Cancel
            </v-btn>
            <v-btn
                color="primary"
                @click="addAssesment"
            >
                Add Assessment
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
export default {
    props: {
        assesments: Array,
        dialog: Boolean,
        markBookPage: Object,
    },

    // created: function () {
    //     this.initialize();
    // },

    data: () => ({
        menu: false,
        assesment: {
            topic: '',
            date: '',
            total: ''
        },
        saveStatus: true,
        saveState: {
            saved: false,
            saving: false,
            error: false,
        },
        errorMessage: '',
    }),

    watch: {
        dialog: function (val) {
            if(val){
                this.initialize();
            }
        }
    },

    methods: {
        initialize () {
            console.log(this.assesments);
        },

        ...mapMutations({
            setAssesment: 'markBook/setMarkBookAssesment',
        }),

        ...mapActions({
            postAssesment: 'markBook/postMarkBookAssesment',
        }),

        numberValidation ($event) {
            if($event.keyCode < 48 || $event.keyCode > 57)
            return $event.preventDefault();
        },

        cancel () {
            this.setSaveStatus();
            this.$emit('close-new-assesment');
        },

        async addAssesment () {
            
            if(!this.validationCheck()){
                return;
            }

            let highestAssesment = 1;

            this.assesments.forEach( (value, index) => {
                if(value){
                    highestAssesment = index;
                }                
            })

            try {
                this.updateAssesment(highestAssesment);
                this.setAssesment(this.assesment);
                const { statusText } = await this.postAssesment();
                console.log(statusText);
                this.$emit('add-assesment');
                this.$emit('close-new-assesment');
            } catch (error) {   
                if(error.response) console.log(error.response);
                else console.log(error);
            }
        },

        updateAssesment (highestAssesment) {
            this.assesment.assesment_number = highestAssesment + 1;
            this.assesment.academic_year_id = this.markBookPage.academic_year_id;
            this.assesment.employee_id = this.markBookPage.employee_id;
            this.assesment.form_class_id = this.markBookPage.form_class_id;
            this.assesment.form_level = this.markBookPage.form_level;
            this.assesment.subject_id = this.markBookPage.subject_id;
            this.assesment.term = this.markBookPage.term;
        },

        setSaveStatus (status = null, errorMsg = null) {
            Object.keys(this.saveState).forEach(state => {
                this.saveState[state] = false;
                if(state == status){
                    this.saveState[state] = true;
                }
            })

            this.errorMessage = errorMsg;
        },

        validationCheck () {
            if(
                !this.assesment.date && 
                !this.assesment.topic && 
                !this.assesment.total
            ){
                console.log("all fields blank");
                this.setSaveStatus('error', 'Fields Blank');
                return false
            }

            return true;
        },
    }
}
</script>
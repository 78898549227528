<template>
    <tr>
        <td style="text-align:center">            
            <v-select
                v-model="itemModel.subject_id"                
                :items="subjects"
                item-value="id"
                outlined
                dense
                hide-details
                @change="updateRecord"
                :disabled="!admin" 
            >
                <template 
                    v-slot:selection="{item}"                                       
                >
                   <span style="font-size:0.8rem; width:40px; text-align:center">{{ item.id }}</span>
                </template>

                <template
                    v-slot:item="data"
                > 
                    {{ data.item.id}} {{ data.item.title }}
                </template>
            </v-select>
        </td>
        <td>{{ itemModel.abbr}}</td>
        <td 
            v-if="!form6Term2"
            style="text-align:center"
        >
            <v-text-field
                v-model="itemModel.course_mark"
                hide-details                
                type="number"
                outlined
                style="font-size:0.8rem"
                @blur="updateRecord"
                :disabled="!admin" 
            ></v-text-field>
        </td>        
        <td 
            v-if="formLevel != 6"
            style="text-align:center"
        >
            <v-text-field
                v-model="itemModel.exam_mark"
                hide-details                
                type="number"
                outlined
                style="font-size:0.8rem"
                @blur="updateRecord"
                :disabled="!admin" 
            ></v-text-field>
        </td>
        <td 
            style="text-align:center"
            v-if="form6Term2"
        >
            <v-text-field
                v-model="itemModel.paper_1"
                hide-details                
                type="number"
                outlined
                style="font-size:0.8rem"
                @blur="updateRecord"
                :disabled="!admin" 
            ></v-text-field>
        </td>
        <td 
            style="text-align:center"
            v-if="form6Term2"
        >
            <v-text-field
                v-model="itemModel.paper_2"
                hide-details                
                type="number"
                outlined
                style="font-size:0.8rem"
                @blur="updateRecord"
                :disabled="!admin" 
            ></v-text-field>
        </td>
        <td 
            style="text-align:center"
            v-if="form6Term2"
        >
            <v-text-field
                v-model="itemModel.paper_3"
                hide-details                
                type="number"
                outlined
                style="font-size:0.8rem"
                @blur="updateRecord"
                :disabled="disablePaper3"
                :filled="disablePaper3" 
            ></v-text-field>
        </td>
        <td 
            style="text-align:center"
            v-if="form6Term2"
        >
            <v-text-field
                v-model="itemModel.ia"
                hide-details                
                type="number"
                outlined
                style="font-size:0.8rem"
                @blur="updateRecord"
                :disabled="!admin" 
            ></v-text-field>
        </td>
        <td 
            v-if="formLevel != 6"
            style="max-width: 1px; white-space: nowrap; overflow:hidden; text-overflow: ellipsis;"
        >            
            <v-select
                v-model="itemModel.coded_comment"                
                :items="codedComments1"
                item-value="id"
                outlined
                dense
                hide-details
                @change="updateRecord"
                :disabled="!admin"  
            >
                <template 
                    v-slot:selection="{item}"                                       
                >
                   <span style="font-size:0.8rem; width:40px; text-align:center">{{ item.id }}</span>
                </template>

                <template
                    v-slot:item="data"
                > 
                    {{ data.item.id}} {{ data.item.detail }}
                </template>
            </v-select>                                   
        </td>
        <td 
            v-if="formLevel != 6"
            style="max-width: 1px; white-space: nowrap; overflow:hidden; text-overflow: ellipsis;"
        >
            <v-select
                v-model="itemModel.coded_comment_1"                
                :items="codedComments1"
                item-value="id"
                outlined
                dense
                hide-details
                @change="updateRecord"
                :disabled="!admin"  
            >
                <template 
                    v-slot:selection="{item}"                                       
                >
                   <span style="font-size:0.8rem; width:40px; text-align:center">{{ item.id }}</span>
                </template>

                <template
                    v-slot:item="data"
                > 
                    {{ data.item.id}} {{ data.item.detail }}
                </template>
            </v-select>                               
        </td>
        <td 
            style="text-align:center"
            v-if="form6Term2"
        >
            <v-text-field
                v-model="total"
                hide-details
                outlined
                style="font-size:0.8rem"
                @blur="updateRecord"
                disabled
            ></v-text-field>
        </td>
        <td 
            style="text-align:center"
            v-if="formLevel == 6"
        >
            <v-textarea
                v-if="form6Term2"
                v-model="itemModel.custom_comment"
                hide-details
                outlined
                style="font-size:0.8rem; width:160px"
                rows="3"
                @blur="updateRecord"
                :disabled="!admin" 
            ></v-textarea>

            <v-textarea
                v-else
                v-model="itemModel.custom_comment"
                hide-details
                outlined
                style="font-size:0.8rem;  width:300px"
                rows="3"
                no-resize
                @blur="updateRecord"
                :disabled="!admin" 
            ></v-textarea>
        </td>

        <td>
            <v-btn 
                icon
                @click="deleteRecord"
                :disabled="!admin" 
            >
                <v-icon
                    small
                >
                    mdi-delete
                </v-icon>
            </v-btn>
        </td>        
    </tr>    
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    props: {
        item: Object,
        formLevel: Number,
        term: Number,
        paper3: Boolean,
        subjectWeights: Array,              
    },
    
    mounted: function () {        
        this.codedComments1 = [...this.codedComments];
        this.codedComments2 = [...this.codedComments];
    },

    data: () => ({
        subject: null,
        codedComments1: [],
        codedComments2: [],
    }),

    computed: {
        ...mapGetters({
            subjects: 'admin/getSubjects',
            codedComments: 'termReports/getCodedComments',
            admin: 'auth/getAdmin',            
        }),

        itemModel () {
            return {...this.item};
        },
        
        disablePaper3 () {
            if(!this.admin) return true;
            else if(this.admin && !this.paper3) return true;
            return false;    
        },

        total () {
            let p1Weight = null, p2Weight = null;
            let p3Weight = null; 
            let incomplete = false;
            let iaWeight = null;
            let [ subject ] = this.subjectWeights;
            
            subject.weightings.forEach(value => {
                switch (value.assesment_id) {
                    case 1:
                        p1Weight = value.weight;
                        break;
                    case 2:
                        p2Weight = value.weight;
                        break;
                    case 3:
                        p3Weight = value.weight;
                        break;
                    case 4:
                        iaWeight = value.weight;
                        break;            
                }
            })

            if(
                p3Weight && 
                (
                    this.itemModel.paper_1 === null ||
                    this.itemModel.paper_2 === null ||
                    this.itemModel.paper_3 === null ||
                    this.itemModel.ia === null
                )
            ) incomplete = true;
            else if(
                !p3Weight &&
                (
                    this.itemModel.paper_1 === null ||
                    this.itemModel.paper_2 === null ||
                    this.itemModel.ia === null
                )
            ) incomplete = true;
            
            if(p3Weight && !incomplete){
                return Math.round(
                    (this.itemModel.paper_1 / 100)*p1Weight +
                    (this.itemModel.paper_2 / 100)*p2Weight +
                    (this.itemModel.paper_3 / 100)*p3Weight +
                    (this.itemModel.ia / 100)*iaWeight
                )
            }
            else if(!p3Weight && !incomplete){
                return Math.round(
                    (this.itemModel.paper_1 / 100)*p1Weight +
                    (this.itemModel.paper_2 / 100)*p2Weight +            
                    (this.itemModel.ia / 100)*iaWeight
                ) 
            }
            return 'Inc';
            
        },

        form6Term2 () {
            // console.log(this.term);
            if(this.formLevel == 6 && this.term == 2)
            return true;
            return false;
        }
    },    

    methods: {
        ...mapActions({
            postTable2Record: 'admin/postUpdateTable2',
            getTable2Records: 'termReports/getTable2Records',
        }),

        ...mapMutations({
            setTable2Record: 'admin/setSelectedTable2Record',           
            setTable2Records: 'termReports/setEditViewTermReportsTable2Records',
            setStudentSubjects: 'termReports/setEditViewTermReportsStudentSubjects',
            setSnackbar: 'admin/setSnackbar',
            setDialogDelete: 'admin/setDialogDelete',
            setSaveStatus: 'termReports/setEditViewTermReportsSaveStatus',
            setSaving: 'termReports/setEditViewTermReportsSaving',
            setSaveError: 'termReports/setEditViewTermReportsSaveError',
            setSaved: 'termReports/setEditViewTermReportsSaved',
        }),

        async updateRecord () { 
            // this.overlay(true);
            // this.loading(true);
            this.setSaving(true);
            this.setSaveStatus("Updating Record");
            this.setSaved(false);
            this.setSaveError(false);

            let table2Record = Object.assign({}, this.itemModel);
            table2Record.subject_id_old = this.item.subject_id;
            table2Record.subject_id_new = this.itemModel.subject_id;
            this.setTable2Record(table2Record);
            try {
                let response = await this.postTable2Record();
                console.log(response);
                this.$emit('update-average');

                response = await this.getTable2Records();
                this.setTable2Records(response.data.table2_records);
                this.setStudentSubjects(response.data.student_subjects);

                this.setSaving(false);
                this.setSaveStatus("Record Updated");
                this.setSaved(true);
                this.setSaveError(false);

                this.setSnackbar({
                    text: `Record Updated`,
                    display: true,
                    color: 'primary',
                }); 

            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.setSnackbar({
                    text: `Error occured`,
                    display: true,
                    color: 'red',
                });
                
                this.setSaving(false);
                this.setSaveStatus("Error Occured!");
                this.setSaved(false);
                this.setSaveError(true);
            }

            // this.overlay(false);
            // this.loading(false);
        },

        deleteRecord () {
            // console.log(`Delete ${this.itemModel.abbr}`);
            // console.log(this.item);
            this.setDialogDelete({
                display: true,
                text: `Delete ${this.itemModel.title} ?`,
                cancel: true,
                deleteSubject: true,
                deleteStudent: false,
                ok: false
            })            
            this.setTable2Record(this.item);
            
        },

        // total () {
        //     let p1Weight = null, p2Weight = null;
        //     let p3Weight = null, iaWeight = null;
        //     let [ subject ] = this.subjectWeights;
            
        //     subject.weightings.forEach(value => {
        //         switch (value.assesment_id) {
        //             case 1:
        //                 p1Weight = value.weight;
        //                 break;
        //             case 2:
        //                 p2Weight = value.weight;
        //                 break;
        //             case 3:
        //                 p3Weight = value.weight;
        //                 break;
        //             case 4:
        //                 iaWeight = value.weight;
        //                 break;            
        //         }
        //     })

        //     console.log(`p1: ${p1Weight}`);
        //     console.log(`p2: ${p2Weight}`);
        //     console.log(`p3: ${p3Weight}`);
        //     console.log(`ia: ${iaWeight}`);

        //     if(p3Weight){
        //         return Math.round(
        //             (this.itemModel.paper_1 / p1Weight) +
        //             (this.itemModel.paper_2 / p2Weight) +
        //             (this.itemModel.paper_3 / p3Weight) +
        //             (this.itemModel.ia / iaWeight)
        //         )
        //     }
        //     return Math.round(
        //         (this.itemModel.paper_1 / p1Weight) +
        //         (this.itemModel.paper_2 / p2Weight) +            
        //         (this.itemModel.ia / iaWeight)
        //     ) 
        // }
        
    }
}
</script>

<style scoped>
    ::v-deep .v-input{
        width: 40px;
    }    

    ::v-deep .v-select__slot{
        height: 20px;
    }

    ::v-deep .v-input__icon{
        height: 5px;
        min-width: 8px;
        width: 8px;
    }

    ::v-deep .v-select.v-text-field--outlined:not(.v-text-field--single-line).v-input--dense .v-select__selections{
        height: 20px;
        padding: 0px;
    }

    ::v-deep .v-input input{
        max-height: 20px;
    }

    ::v-deep .v-btn--icon.v-size--default{
        height: 20px;
        width: 20px;
    }

    
</style>